import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  LinkedinShareButton
} from 'react-share';
import config from '../../config/config'
import { stripTags } from '../../modules/utils';

class SocialShare extends Component {
  
  render() {
    const {title, description, path, tags, media} = this.props
    const text = config.title + ': ' + title + "\n" + stripTags(description)
    let hashtags = ['imagining','greatimagining'];
    if (tags instanceof Array) {
      hashtags = [...hashtags, ...tags.map(tg => tg.name.toLowerCase())]
    }
    const url = window.location.origin + path
    return (
      <div className="social-share-buttons">
        <div className="text-label">Share</div>
        <TwitterShareButton title={text} url={url} hashtags={hashtags}><Icon className="twitter" /></TwitterShareButton>
        <FacebookShareButton url={url}><Icon className="facebook" /></FacebookShareButton>
        <PinterestShareButton description={text} url={url} media={media}><Icon className="pinterest" /></PinterestShareButton>
        <LinkedinShareButton description={text} url={url} media={media}><Icon className="linkedin" /></LinkedinShareButton>
      </div>
    )
  }
}

export default SocialShare
