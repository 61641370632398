import React, { Component } from 'react'
import MenuList from './menu-list'
import config from '../../config/config'

class Footer extends Component {
  render() {
    const { subtitle, logos, menuItems, current, creditLinks, bottomSubTitles } = this.props.data
    const hasBottomTitles = bottomSubTitles instanceof Array && bottomSubTitles.length > 0
    const { logosEnabled } = config.footerData;
    const showLogos = logosEnabled !== false;
    return (
      <footer className="footer">
        <section className="bottom">
          <div className="inner-wrapper">
            {showLogos && (<div className="inner">
              <h2 className="partners">{subtitle}</h2>
              <div className="logos">
                {logos.length > 0 &&
                  logos.map((img, index) => (
                    <figure className="logo" key={['figure-logo', index].join('-')}>
                      <img src={img.uri} alt={img.title} className={img.className} />
                      <figcaption>{img.title}</figcaption>
                    </figure>
                  ))}
              </div>
            </div>)}
          </div>
        </section>
        <section className="top inner">
          <div className="inner-wrapper">
            <div className="inner">
              <MenuList
                items={menuItems}
                current={current}
                className="footer-menu"
                signup={true}
              />
              <h2 className="subtitles">{hasBottomTitles && bottomSubTitles.map((subTitle, si) => (<span className="sub" key={['footer-sub-word', si].join('-')}>{subTitle}</span>))}</h2>
              <ul className="menu credit-links">
                {creditLinks.map((link, index) => (
                  <li key={index}><a href={link.to} target="_blank" rel="noopener noreferrer" key={['credit-link', index].join('-')}>{link.content}</a></li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </footer>
    )
  }
}

export default Footer
