import React from 'react'
import { Container } from 'semantic-ui-react'
import renderHTML from 'react-render-html'
import { Link } from 'react-router-dom'
import { fetchPosts } from '../../modules/api'
import { assignPageVars } from '../../modules/cms'
import moment from 'moment'
import PropTypes from 'prop-types'
import { spacedWord } from '../../modules/utils'
import config from '../../config/config'

const HeadingOne = ({ title, aside }) => {
  const cls = ['title']
  if (aside) {
    cls.push('aside')
  }
  const titleClassNames = cls.join(' ')
  const titleSpacedWords = title.trim().split(/\s+/).map(spacedWord)
  return <h1 className={titleClassNames}>{titleSpacedWords.map((word, wi)  => (<span className="word" key={['word', wi].join('-')}>{word}</span>))}</h1>
}

class Page extends React.Component {
  state = {
    title: 'Page title',
    text: '',
    date: null,
    showDate: false,
    hasMainImage: false,
    hasSubtitle: false,
    subtitle: '',
    mainImage: null,
    extra: '',
    hasExtra: false,
    hasVideo: false,
    videoHTML: ''
  }

  async componentWillMount() {
    const { pages, slug, location } = this.props
    let page = null
    if (slug && pages instanceof Array) {
      let alias = ''
      switch (slug) {
        case '--info':
          alias = window.location.pathname.split('/').pop()
          break
        default:
          alias = slug
          break
      }
      page = pages.find(p => p.slug === alias)
    } else {
      const postData = await fetchPosts()
      if (postData.valid) {
        let slug = location.pathname.split('/').pop()
        page = postData.items.find(p => p.slug === slug)
        this.setState({ showDate: true })
      }
    }
    const videoData = page instanceof Object ? page.content.find(item => item.type.includes('embed')) : null
    const hasVideo = videoData instanceof Object
    if (hasVideo) {
      this.setState({
        hasVideo,
        video: videoData.html
      })
    }
    if (page) {
      if (page.title) {
        if (page.content instanceof Array) {
          assignPageVars(page, true).then(vars => {
            this.setState(vars)
          })
        }
      }
    }
  }

  render() {
    const {
      title,
      subtitle,
      date,
      showDate,
      text,
      hasMainImage,
      hasSubtitle,
      mainImage,
      extra,
      hasExtra,
      hasVideo,
      video,
    } = this.state
    const { slug } = this.props
    const hasDate = showDate && typeof date === 'string' && /\d+-\d/.test(date)
    const hasCallToActions = ['project','home'].includes(slug);
    const callToActions = hasCallToActions? config.callToActions.map((item, index) => {
      const key = ['cta', index].join('-')
      const linkClasses = key;
      return {...item, key, linkClasses }
    }) : [];
    const cls = ['text-content']

    const textContentClass = cls.join(' ')
    let extraBelowMain = hasExtra && hasMainImage
    const hasMedia = hasMainImage || hasVideo
    const showMainImage = hasMainImage && !hasVideo
    return (
      <Container className="main-content">
        {hasMedia ? (
          <section className="main-area two-thirds">
            {hasSubtitle && <h2 className="subtitle">{renderHTML(subtitle)}</h2>}
            {hasVideo && renderHTML(video)}
            {showMainImage && renderHTML(mainImage)}
          </section>
        ) : (
            <HeadingOne title={renderHTML(title)} aside={true} />
          )}
        {hasMainImage ? (
          <aside className={textContentClass}>
            <HeadingOne title={title} aside={false} />
            {hasDate && (
              <time className="date">{moment(date).format('D MMM YYYY')}</time>
            )}
            <article className="body">{renderHTML(text)}</article>
          </aside>
        ) : (
            <article className={textContentClass}>
              {hasSubtitle && <h2 className="title">{renderHTML(subtitle)}</h2>}
              {hasDate && (
                <time className="date">{moment(date).format('D MMM YYYY')}</time>
              )}
              <div className="body">
                {renderHTML(text)}
                {hasExtra && renderHTML(extra)}
              </div>
            </article>
          )}
        {extraBelowMain && (
          <section className="extra-area two-thirds">
            {renderHTML(extra)}
          </section>
        )}
        {hasCallToActions && (
          <section className="call-to-actions middle">
            {callToActions.map((item, ai) => (
              <div className={item.classNames} key={item.key}>
                <Link to={item.to} className={item.linkClasses}>
                {item.lines.map((line, li) => (<span className="line" key={['cta', item.key, li].join('-')}>{line}</span>))}
              </Link>
              </div>
            ))}
          </section>
        )}
      </Container>
    )
  }
}

Page.propTypes = {
  pages: PropTypes.array,
  slug: PropTypes.string
}

Page.defaultProps = {
  pages: [],
  slug: ''
}

export default Page
