import React from 'react'
import { Container, Segment, Icon, Form, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import ReactPlayer from 'react-player'
import {
  postImage,
  fetchUser,
  fetchTags,
  updateImage,
  deleteImage,
  editUser,
  saveUserData,
  storeUser,
  saveEmbed,
  saveImageWeight,
  deleteEmbed,
  updateEmbeds
} from '../../modules/api'
import config from '../../config/config'
import { fromLocal, toLocal } from '../../modules/localstore'
import Photo from '../elements/photo'
import ReactTags from 'react-tag-autocomplete'
import renderHTML from 'react-render-html'
import { applyPageContent } from '../../modules/cms'
import { extractId, isNumeric, notEmptyString, removeBodyClass, sanitize, smartCastFloat, validUrl } from '../../modules/utils'
import LinkItem from '../elements/link-item'
import Location from '../elements/location'
import { validPlayerUrlType } from '../../media-validator';
import sortableElement from '../../modules/sortable';

class Contribute extends React.Component {
  _isMounted = false
  loadTO = 0;
  saveInterval = 0;
  keydownEv = 0;

  state = {
    title: 'Contribute',
    text: '',
    widgetLoaded: false,
    widget: null,
    loadAttempts: 0,
    images: [],
    loading: false,
    loaded: false,
    numImages: 0,
    hasSelection: false,
    currId: '',
    caption: '',
    editable: false,
    description: '',
    selectedTags: [],
    tags: [],
    userId: '',
    agreed: false,
    signUp: false,
    showMessage: false,
    showDeleteButtons: false,
    message: '',
    errMsg: '',
    textExpanded: false,
    urls: [],
    imageUrls: [],
    embeds: [],
    showEmbed: false,
    embedMsg: '',
    embedState: 0,
    newEmbedUrl: '',
    newEmbedTitle: '',
    embedIndex: -1, // index of embed being edited
    topic: '',
    orgName: '',
    displayName: '',
    coords: {
      lat: 0,
      lng: 0
    },
    locSuggestions: [],
    searching: false,
    autoClose: false
  }

  constructor(props) {
    super(props);
    this.rTags = React.createRef();
  }

  /*   shouldComponentUpdate(nextProps, nextState) {
      return (
        this.props.location.pathname !== nextProps.location.pathname ||
        nextState.loaded
      )
    } */

  componentDidMount() {
    if (!this._isMounted) {
      if (!this.state.loading && !this.state.loaded) {
        this.matchUser()
        this.initCloudinary()
        this.updateImages()
        this.fetchRelatedData()
        this._isMounted = true
        this.setState({ loading: true })
        this.loadContent()
      }
    }
    this.loadTO = setTimeout(() => {
      if (this._isMounted) {
        this.setState({ loaded: true })
      }
    }, 250)
    /* this.saveInterval = setInterval(() => {
      if (this.hasSelection && notEmptyString(this.currId) && this._isMounted) {
        this.updateImageData(null, false);
      }
    }, 30 * 1000) */
    window.addEventListener("keydown",this.keydownHandler);
  }

  componentWillUnmount() {
    this._isMounted = false
    if (this.loadTO) {
      clearTimeout(this.loadTO)
    }
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    window.removeEventListener("keydown", this.keydownHandler);
  }

  loadContent = () => {
    const slug = 'contribute'
    const { pages } = this.props
    applyPageContent(slug, pages).then(vars => {
      this.setState(vars)
    })
  }

  fetchRelatedData = async () => {
    const tagData = await fetchTags()
    if (tagData.items) {
      const suggestions = tagData.items.map((t, ti) => {
        return { id: t._id, name: t.name }
      })
      this.setState({
        tags: suggestions
      })
      setTimeout(this.initSortable, 500);
    }
  }

  keydownHandler = (e) => {
    if (e instanceof KeyboardEvent) {
      switch (e.key.toLowerCase()) {
        case "escape":
          this.escape();
          break;
      }
    }
  }

  escape() {
    this.updateImageData(null, true)
    removeBodyClass('show-overlay');
    setTimeout(() => {
      this.setState({
        showEmbed: false,
        embedIndex: -1,
      });
    }, 375);
  }

  initCloudinary = async () => {
    let body = document.body
    let scriptId = 'cloudinary-upload-script'
    let st = document.getElementById(scriptId)

    let ts = 300
    if (!st) {
      st = document.createElement('script')
      st.id = scriptId
      st.src = 'https://widget.cloudinary.com/v2.0/global/all.js'
      st.type = 'text/javascript'
      body.appendChild(st)
      ts = 1200
    }
    setTimeout(() => this.loadWidget(), ts)
  }

  matchUser = async () => {
    const user = await fetchUser()
    if (user) {
      this.setState({
        userId: user._id
      })
      if (user.agreed) {
        const keys = Object.keys(user);
        const urls = keys.includes('urls') && user.urls instanceof Array ? user.urls : [];
        const embeds = keys.includes('embeds') && user.embeds instanceof Array ? user.embeds : [];
        const orgName = keys.includes('orgName') && notEmptyString(user.orgName) ? user.orgName : ''
        const displayName = keys.includes('displayName') && notEmptyString(user.displayName) ? user.displayName : ''
        const hasLoc = keys.includes('location') && user.location instanceof Object && Object.keys(user.location).includes('placename');
        const userPlacename = hasLoc ? user.location.placename : '';
        let userCoords = { lat: 0, lng: 0 };
        if (hasLoc) {
          const { lat, lng } = user.location;
          if (isNumeric(lat) && isNumeric(lng)) {
            userCoords = {
              lat: smartCastFloat(lat),
              lng: smartCastFloat(lng)
            }
          }
        }
        this.setState({
          userId: user._id,
          agreed: true,
          signUp: user.signUp === true,
          urls,
          orgName,
          displayName,
          userPlacename,
          userCoords,
          embeds,
        })
      }
    }
  }

  updateImages = async widget => {
    const stored = fromLocal('user-images', 86400)
    let currImgs = []
    if (stored.valid) {
      if (stored.data instanceof Array) {
        currImgs = stored.data
      }
    }
    const imgs = currImgs
      .map(img => {
        img.className = 'inactive'
        if (/^untitled\.\./i.test(img.caption)) {
          img.caption = ''
        }
        img.approved = false
        switch (img.status) {
          case 'approved':
            img.approved = true
            break
          case 'uploaded':
          case 'authorised':
          case 'rejected':
            img.mayDelete = true
            break
          default:
            img.mayDelete = false
            break
        }
        img.needsCaption = img.caption.length < 3
        return img
      })
      .sort((a, b) => b.created - a.created)
    this.setState({
      images: imgs,
      numImages: stored.data.length
    })

    if (widget) {
      setTimeout(() => {
        this.closeWidget(widget);
      }, 30000)
    }
  }

  closeWidget = (widget) => {
    const { showEmbed, hasSelection, images } = this.state;
    if (!showEmbed && !hasSelection) {
      widget.close()
      if (images instanceof Array && images.length > 0) {
        const withoutCaptions = images.some(img => notEmptyString(img.caption) === false);
        if (withoutCaptions) {
          this.setState({
            message: config.labels.thankyouUploading,
            showMessage: true,
            autoClose: false,
            showEmbed: false,
            embedIndex: -1
          })
        }
      }
    }

    setTimeout(() => {
      if (this.state.images.length > 0) {
        this.selectImage({
          _id: this.state.images[0]._id,
          className: 'inactive'
        })
      }
    }, 500);
    setTimeout(() => {
      this.hideMessage()
    }, 30 * 1000)
  }

  hideMessage = () => {
    this.setState({
      showMessage: false,
      showDeleteButtons: false
    })
  }


  updateImageData = (e, closeMode) => {
    if (e instanceof Object) {
      e.stopPropagation()
    }
    const closeOnSave = closeMode !== false
    let { currId, selectedTags, caption, description, placename, topic, coords, imageUrls } = this.state
    let valid = false
    let errMsg = ''
    const tags = selectedTags.map(t => t.name.replace(/^#/, ''));
    if (currId.length > 3) {
      caption = caption.trim()
      description = description.trim()
      let data = {
        caption,
        description,
        tags,
        topic,
      }
      if (notEmptyString(placename)) {
        const { lat, lng } = coords
        data.location = isNumeric(lat) && isNumeric(lng) ? { placename, lat, lng } : { placename }
      }
      if (imageUrls instanceof Array) {
        data.urls = imageUrls;
      }
      valid = caption.length > 1 || description.length > 2 || tags.length > 0;
      if (valid) {
        updateImage(currId, data, response => {
          if (response.success) {
            this.updateImages()
            if (closeOnSave) {
              this.selectImage({
                _id: currId,
                className: 'active'
              })
            }
          }
        })
      } else {
        errMsg = 'Please add a caption'
      }
    }
    this.setState({ errMsg: errMsg })
  }

  updateUser = async () => {
    const { orgName, displayName, userId, userPlacename, userCoords } = this.state;
    const user = await fetchUser();
    if (user instanceof Object && notEmptyString(displayName, 2)) {
      const { location } = user;
      const hasUserLoc = location instanceof Object
      const userLocKeys = hasUserLoc ? Object.keys(location) : [];
      const currLat = userLocKeys.includes("lat") ? location.lat : 0;
      const currLng = userLocKeys.includes("lng") ? location.lng : 0;
      const locObj = location instanceof Object ? location : { placename: '', lat: 0, lng: 0 };
      const newPlaceName = userPlacename !== locObj.placename || userCoords.lat !== currLat || userCoords.lng !== currLng
      if (orgName !== user.orgName || displayName !== user.displayName || newPlaceName) {
        setTimeout(() => {
          saveUserData(userId, {
            orgName,
            displayName,
            location: {
              placename: userPlacename,
              ...userCoords
            }
          })
        }, 1000)
      }
    }
  }

  handleDeleteImage = async (img, e) => {
    e.stopPropagation()
    if (img.user) {
      const message = `Do you want to delete this image?`;
      this.setState({
        message,
        showMessage: true,
        showDeleteButtons: true
      })
    }
  }

  handleDeleteEmbed = async () => {
    const { embeds, embedIndex, userId } = this.state;
    if (embedIndex >= 0 && embedIndex < embeds.length) {
      deleteEmbed(userId, embedIndex).then(result => {
        embeds.splice(embedIndex, 1);
        this.setState({ embeds, embedIndex: -1, showEmbed: false });
        updateEmbeds(embeds);
      })
    }
  }

  deleteSelectedImage = async () => {
    const { currId, images } = this.state
    const img = images.find(img => img._id === currId);
    if (img instanceof Object && img.user) {
      let userId = ''
      if (typeof img.user === 'string') {
        userId = img.user
      } else if (img.user._id) {
        userId = img.user._id
      }
      if (userId === this.state.userId) {
        deleteImage(img._id).then(res => {
          if (res.valid) {
            const parts = ['Image']
            if (notEmptyString(img.caption, 2)) {
              parts.push(`(${img.caption})`)
            }
            parts.push('removed');
            const message = parts.join(' ')
            this.setState({
              message,
              showMessage: true,
              showDeleteButtons: false
            })
            setTimeout(() => this.removeImage(img), 1000)
          }
        })
      }
    }
  }

  buildEmbedData() {
    const { embeds, embedIndex } = this.state;
    /* const parent = document.querySelector('#user-images');
    let previewWidth = 640;
    let previewHeight = 360;
    let parHeight = 800;
    let parWidth = 450;
    if (parent instanceof HTMLElement) {
      const first = parent.querySelector('figure');
      if (first instanceof HTMLElement) {
        const rect = first.getBoundingClientRect();
        const parRect = parent.getBoundingClientRect();
        previewWidth = rect.width;
        previewHeight = previewWidth / 16 * 9;
        parWidth = parRect.width;
        parHeight = parWidth / 16 * 9;
      }
    } */
    if (embeds instanceof Array) {
      return embeds.map((row, ri) => {
        const { uri, title } = row;
        const uriStr = uri ? uri : '';
        const titleStr = title ? title : '';
        const key = ['embed', ri].join('-');
        const selected = ri === embedIndex;
        const activeClass = selected ? 'active' : 'inactive';
        const className = ['large-placeholder', activeClass].join(' ');

        // const height = selected ? parHeight : previewHeight;
        const height = 'auto';
        // const width = selected ? parWidth : previewWidth;
        const width = '100%';
        return { uri: uriStr, title: titleStr, key, height, width, className }
      })
    } else {
      return [];
    }
  }

  removeImage = img => {
    let imgs = this.state.images.filter(im => im._id !== img._id)
    this.setState({
      images: imgs,
      numImages: imgs.length,
      hasSelection: false,
      showMessage: false
    })
    toLocal('user-images', imgs)
  }

  selectTag = async item => {
    const { selectedTags } = this.state
    const newTags = [];
    const keys = item instanceof Object ? Object.keys(item) : [];
    const addMode = keys.includes('name');
    const tag = addMode ? item.name : 'item';
    if (addMode) {
      const newTagStrs = tag.trim().replace(/^#/, '').trim().split('#').map(tg => tg.trim()).filter(tg => selectedTags.some(t => sanitize(tg) === sanitize(t.text)) === false);

      for (const nTag of newTagStrs) {
        const newTag = nTag.replace(/^\s*#/, '').trim();
        if (newTag.length > 2) {
          newTags.push({
            id: sanitize(newTag),
            name: newTag
          });
        }
      }
    }
    if (newTags.length > 0) {
      newTags.forEach(tg => {
        selectedTags.push(tg);
      })
    }
    this.setState({ selectedTags })
  }

  handleDrag = (tag, currPos, newPos) => {
    let tags = this.state.selectedTags
    tags.splice(currPos, 1)
    tags.splice(newPos, 0, tag)
    this.setState({ selectedTags: tags })
  }

  deleteTag = async tagIndex => {
    if (typeof tagIndex === 'number' && tagIndex >= 0) {
      let selTags = this.state.selectedTags
      if (tagIndex < selTags.length) {
        selTags.splice(tagIndex, 1)
        this.setState({ selectedTags: selTags })
      }
    }
  }

  selectImage = async image => {
    const { showEmbed, embedIndex } = this.state;
    let newState = image.className === 'active' ? 'inactive' : 'active'
    let imgs = this.state.images.map(img => {
      img.className = image._id === img._id ? newState : 'inactive'
      return img
    })
    const keys = image instanceof Object ? Object.keys(image) : [];
    let selTags = []
    let currId = ''
    let hasSelection = false
    let caption = ''
    let description = ''
    let placename = ''
    let coords = { lat: 0, lng: 0 }
    let imageUrls = [];
    if (newState === 'active') {
      if (image.tags) {
        selTags = image.tags.map((t, ti) => {
          return {
            id: t._id,
            name: t.name
          }
        })
      }
      if (image.caption) {
        caption = image.caption
      }
      if (image.description) {
        description = image.description
      }

      if (image.hasPlacename) {
        placename = image.location.placename
        const lcKeys = Object.keys(image.location);
        if (lcKeys.includes('lat')) {
          coords = { lat: image.location.lat, lng: image.location.lng }
        }
      }

      if (image.urls) {
        imageUrls = image.urls
      }

      currId = image._id
      hasSelection = true
    } else {
      if (showEmbed) {
        if (embedIndex >= 0) {
          this.updateEmbeds();
        }
      } else {
        this.updateImageData(null, true)
      }
    }
    const topic = keys.includes("topic") ? image.topic : '';
    const status = keys.includes("status") ? image.status : '';
    const editable = status !== 'approved'
    //this.validateEmbed(embed);
    const ts = hasSelection ? 0 : 125
    if (newState === 'inactive') {
      if (showEmbed) {
        this.setState({showEmbed: false, embedIndex: -1 });
      }
    }
    setTimeout(() => {
      this.setState({
        images: imgs,
        currId,
        selectedTags: selTags,
        caption,
        description,
        topic,
        placename,
        coords,
        imageUrls,
        hasSelection,
        editable,
      })
    }, ts)
  }

  validateEmbed(value = '') {
    let embedMsg = "";
    let embedState = 0;
    let info = { valid: false, type: '', provider: '' };
    if (notEmptyString(value, 5)) {
      info = validPlayerUrlType(value);
      if (info instanceof Object) {
        const { valid, type, provider } = info;
        if (!valid) {
          embedMsg = validUrl(value) ? "unsupported format" : "invalid URL";
          embedState = -1;
        } else {
          embedMsg = [type, provider].join(': ');
          embedState = 1;
        }
      }
    }
    this.setState({ embedMsg, embedState })
    return info;
  }

  validateValue = (name, value) => {
    switch (name) {
      case "embed":
        this.validateEmbed(value);
        break;
    }
  }

  updateValue = e => {
    if (e.target) {
      const { name, value } = e.target;
      const params = {
        [name]: value
      }
      this.validateValue(name, value);
      this.setState(params)
    }
  }

  updateLocation = (data = null, mode = 'image') => {
    if (data instanceof Object && Object.keys(data).includes("placename")) {
      if (mode === 'user') {
        this.setState({
          userPlacename: data.placename,
          userCoords: data.coords,
        })
      } else {
        this.setState(data);
      }
    }
  }

  updateDescription = (desc) => {
    this.setState({
      description: desc
    })
  }

  updateUserFields = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      this.setState({
        [name]: value
      })
    }
  }

  updateEmbeds = async () => {
    const { userId, newEmbedUrl, newEmbedTitle, embedIndex, currId } = this.state;
    if (notEmptyString(newEmbedTitle)) {
      const info = this.validateEmbed(newEmbedUrl);

      if (info.valid) {
        const mode = notEmptyString(currId, 12)? "image" : "user";
        saveEmbed(userId, newEmbedUrl, newEmbedTitle, currId, mode, embedIndex).then(data => {
          const { embeds } = data;
          if (embeds instanceof Array) {
            this.setState({ embeds, newEmbedUrl: '', newEmbedTitle: '', showEmbed: false, embedIndex: -1 });
            fetchUser().then(user => {
              if (user instanceof Object) {
                storeUser({ ...user, embeds });
              }
            });
          }
        })
      }
    }
  }

  updateLinks = async (link, mode = 'user') => {
    const { urls, imageUrls, userId } = this.state;
    const imageMode = mode === 'image';
    const refUrls = imageMode ? imageUrls : urls;
    const linkItems = refUrls instanceof Array ? refUrls.map(u => {
      const { type, uri } = u;
      return { type, uri }
    }) : []
    let save = false
    if (link instanceof Object) {
      const keys = Object.keys(link);
      if (keys.includes("remove")) {
        const { remove } = link
        if (remove >= 0 && remove < linkItems.length) {
          linkItems.splice(remove, 1);
          save = true;
        }
      } else if (keys.includes("uri")) {
        const { uri, type, index } = link
        if (notEmptyString(uri, 6) && notEmptyString(type, 2)) {
          const newLink = { uri, type };
          if (index >= 0 && index < refUrls.length) {
            const oldLink = linkItems[index]
            if (oldLink.uri !== newLink.uri || oldLink.type !== newLink.type) {
              linkItems[index] = newLink
              save = true
            }
          } else {
            linkItems.push(newLink)
            save = true
          }
        }
      }
      if (save) {
        if (imageMode) {
          this.setState({ imageUrls: linkItems });
        } else {
          this.setState({ urls: linkItems });
          const user = await fetchUser();
          storeUser({ ...user, urls: linkItems });
          saveUserData(userId, { urls: linkItems });
        }
      }
    }
  }

  updateImageLinks = async (link) => {
    await this.updateLinks(link, 'image');
  }

  addUrl = (mode = 'user') => {
    const imageMode = mode === 'image'

    const { urls, imageUrls } = this.state;
    const refUrls = imageMode ? imageUrls : urls;
    const newIndex = refUrls.length;
    refUrls.push({ uri: "", type: "web", index: newIndex, key: ['url', 'web', newIndex].join('-') })
    const edited = imageMode ? { imageUrls: refUrls } : { urls: refUrls }
    this.setState(edited);
  }

  addImageUrl = () => {
    this.addUrl('image')
  }

  handleSignUp = e => {
    if (e.target) {
      const { name, checked } = e.target;
      if (name === 'signUp') {
        const params = {
          signUp: checked
        }
        editUser(this.state.userId, params).then(u => {
          this.setState({
            signUp: checked
          })
        })
      }
    }
  }

  agreeToTerms = e => {
    if (e.target.name) {
      if (e.target.checked) {
        const params = {
          agreed: true
        }
        editUser(this.state.userId, params).then(u => {
          this.setState({
            agreed: true
          })
          this.loadWidget()
        })
      }
    }
  }

  loadWidget = async () => {
    let la = this.state.loadAttempts
    let widget = null
    if (window.cloudinary) {
      widget = document.getElementById('upload-widget')
      la++
      this.setState({ widget: widget, loadAttempts: la })
    } else if (la < 5) {
      setTimeout(() => {
        this.loadWidget()
      }, 2000)
    }
  }

  removeCloudinaryIframe = () => {
    let iframes = document.querySelectorAll('iframe')
    let loaded = false
    let ifr = null
    if (iframes.length) {
      for (let i = 0; i < iframes.length; i++) {
        ifr = iframes[i]
        if (ifr.src) {
          loaded = ifr.src.includes('cloudinary')
          if (loaded) {
            ifr.parentNode.removeChild(ifr)
            break
          }
        }
      }
    }
  }

  handleAddMedia() {
    const { showEmbed } = this.state;
    if (showEmbed) {
      this.updateEmbeds();
    } else {
      this.openWidget();
    }
  }

  openWidget = async () => {
    if (this.state.widget) {
      this.removeCloudinaryIframe()
      const user = await fetchUser();
      const widget = window.cloudinary.openUploadWidget(
        config.cloudinary.widget,
        (error, result) => {
          if (!error) {
            switch (result.event) {
              case 'success':
                if (result.info) {
                  result.info.caption = 'untitled.....'
                  result.info.user = user._id
                  postImage(result.info, 'upload', data => {
                    if (data.success) {
                      this.updateImages(widget)
                      this.setState({ autoClose: true });
                    }
                  })
                }
                break
              case 'close':
              case 'queues-end':
                const { autoClose } = this.state;
                if (autoClose) {
                  this.closeWidget(widget)
                }
                break
            }
          }
        }
      )
    }
  }

  buildImageClassNames(img) {
    const { status, highlighted, className } = img;
    const cls = ["preview", status];
    if (className) {
      cls.push(className)
    }
    if (highlighted) {
      cls.push('highlighted')
    }
    return cls.join(' ')
  }

  addEmbed = (img) => {
    const { embeds } = this.state;
    const currId = extractId(img);
    const imgTitle = img instanceof Object && notEmptyString(img.caption) ? img.caption : '';
    const embedIndex = this.embedImageIndex(embeds, img);
    const newEmbedUrl = embedIndex < 0 ? '' : embeds[embedIndex].uri;
    const newEmbedTitle = embedIndex < 0 ? imgTitle : embeds[embedIndex].title;
    if (notEmptyString(currId, 12)) {
     this.enlargeSelected(currId);
    }
    this.setState({ showEmbed: true, currId, embedIndex, newEmbedTitle, newEmbedUrl })
  }

  enlargeSelected = (currId = '') => {
    const images = this.state.images.map(img => {
      img.className = img._id === currId ? 'active' : 'inactive'
      return img
    });
    this.setState({images});
  }

  embedImageIndex(embeds = [], img = null) {
    if (embeds instanceof Array && img instanceof Object) {
      const imgId = extractId(img);
      return embeds.findIndex(em => {
        if (em instanceof Object) {
          if (em.image) {
            return em.image.toString() === imgId
          }
        }
        return false;
      });
    } else {
      return -1;
    }
  }

  embedEditLabel = (img) => {
    const { embeds } = this.state;
    const matched = this.embedImageIndex(embeds, img) >= 0;
    return matched ? "Edit related embed" : "Add embed";
  }

  editEmbed(index = 0) {
    const { embeds, images } = this.state;
    const { title, uri, image } = index < embeds.length ? embeds[index] : { title: '', uri: '', image: '' };
    const img = images.find(im => extractId(im) === image);
    if (img) {
      this.setState({currId: img._id});
      this.enlargeSelected(img._id);
    }
    this.setState({ showEmbed: true, embedIndex: index, newEmbedTitle: title, newEmbedUrl: uri });
  }

  toggleTextExpanded = () => {
    this.setState({
      textExpanded: this.state.textExpanded !== true
    })
  }

  updateImageOrder() {
    const { userId, images } = this.state;
    const ids = images.map(im => im._id);
    saveImageWeight(userId, ids, false).then(result => {
      if (result.valid) {
        toLocal('user-images', images);
      }
    });
  }

  calcImageContainerStyle = () => {
    const { showEmbed, currId } = this.state;
    let imageContainerStyles = {};
    if (showEmbed) {
      const el = document.getElementById(currId);
      if (el instanceof HTMLElement) {
        const imgEl = el.querySelector('img');
        if (imgEl instanceof HTMLElement) {
          const size = imgEl.getBoundingClientRect();
          if (size) {
            const targetHeight = (size.height + 16) + "px";
            imageContainerStyles = {minHeight: targetHeight, maxHeight: targetHeight };
          }
        }
      }
    }
    return imageContainerStyles;
  }

  initSortable = () => {
    const element = document.getElementById('user-images');
    if (element instanceof HTMLElement) {
      const hasOnAttr = element.hasAttribute("data-sort");
      if (!hasOnAttr) {
        element.setAttribute("data-sort", "on");
        sortableElement(element, ".handle", (e) => {
          const { newIndex, oldIndex, target } = e;
          if (newIndex < target.childNodes.length) {
            const { images } = this.state;
            if (
              oldIndex >= 0 &&
              oldIndex < images.length &&
              newIndex >= 0 &&
              newIndex < images.length &&
              newIndex !== oldIndex
            ) {
              const refItem = images.splice(oldIndex, 1)[0];
              images.splice(newIndex, 0, refItem);
              this.updateImageOrder();
            }
          }
        });
      }
    } else {
      setTimeout(this.initSortable, 500);
    }
  }

  render() {
    const {
      title,
      subtitle,
      text,
      images,
      numImages,
      hasSelection,
      selectedTags,
      tags,
      caption,
      description,
      placename,
      coords,
      userPlacename,
      userCoords,
      editable,
      agreed,
      signUp,
      showMessage,
      message,
      errMsg,
      urls,
      embeds,
      topic,
      imageUrls,
      displayName,
      orgName,
      showDeleteButtons,
      showEmbed,
      embedMsg,
      embedState,
      embedIndex,
      newEmbedUrl,
      newEmbedTitle,
    } = this.state
    let { textExpanded } = this.state
    let cls = ['main-area', 'user-images', 'half']
    if (hasSelection || showEmbed) {
      cls.push('show-selection')
      if (showEmbed) {
        cls.push('show-large-embed')
      } else {
        cls.push('show-large-image')
      }
    }
    if (showMessage) {
      cls.push('show-message')
    }
    const mainClassNames = cls.join(' ')
    const hasSubtitle = typeof subtitle === 'string' ? subtitle.length > 1 : false
    cls = ['edit-panel']
    if (!editable) {
      cls.push('disabled')
    }
    const formClassNames = cls.join(' ')

    cls = ['details-wrapper']
    let textContractible = agreed && numImages > 0
    if (!textContractible) {
      textExpanded = true
    }
    if (textContractible && !textExpanded) {
      cls.push('text-contracted')
    }
    const textPaneClasses = cls.join(' ')

    const toggleTextExpandedLabel = textExpanded
      ? 'Hide instructions'
      : 'Show instructions'
    if (urls.length < 1) {
      urls.push({
        uri: '',
        type: 'web'
      })
    }
    if (imageUrls.length < 1) {
      imageUrls.push({
        uri: '',
        type: 'web'
      })
    }
    const imageUrlItems = imageUrls.map((urlItem, index) => {
      const key = ['image-url', urlItem.type, index].join('-');
      return { ...urlItem, key, index }
    })
    const urlItems = urls.map((urlItem, index) => {
      const key = ['url', urlItem.type, index].join('-');
      return { ...urlItem, key, index }
    })
    const { labels } = config
    const editUserOnly = !hasSelection
    const rTagsClassNames = { searchWrapper: "textfield textfield-long" };
    const delimiters = ['Enter', 'Tab'];
    const hasEmbeds = embeds instanceof Array && embeds.length > 0;
    const embedItems = hasEmbeds ? this.buildEmbedData() : [];
    const uploadLabel = showEmbed ? embedIndex < 0 ? "Add embedded media" : "Save embedded media" : "Upload files";
    const uploadIcon = showEmbed ? "video" : "cloud upload";
    const tagInputPlaceholder = "Add new tag and hit enter or tab";
    const imageContainerStyles = this.calcImageContainerStyle();
    return (
      <Container className="main-content">
        <aside className="side-panel contribute-form">
          <h2 className="title">{title}</h2>
          {hasSubtitle && <h3 className="subtitle">{subtitle}</h3>}
          <article className={textPaneClasses}>
            {showEmbed && <fieldset>
              <input
                name="newEmbedUrl"
                type="text"
                value={newEmbedUrl}
                minLength={2}
                size={64}
                maxLength={255}
                placeholder="https://media.url"
                className="textfield textfield-long"
                onChange={this.updateValue}
                title="Copy and paste a valid Youtube or Vimeo URL"
              />
              <input
                name="newEmbedTitle"
                type="text"
                value={newEmbedTitle}
                minLength={2}
                size={64}
                maxLength={128}
                placeholder="Media title"
                className="textfield textfield-long"
                onChange={this.updateValue}
                title="Maximum of 100 characters"
              />
              <Icon
                className="trash"
                title="Delete"
                onClick={e => this.handleDeleteEmbed()}
              />
              {embedState < 1 ? <p className="error">{embedMsg}</p> : embedState > 1 ? <p className="feedback">{embedMsg}</p> : <p></p>}
            </fieldset>}
            {hasSelection ? (
              <div className={formClassNames}>
                <h4>Edit photo details</h4>

                <input
                  name="caption"
                  type="text"
                  value={caption}
                  minLength={2}
                  size={64}
                  maxLength={64}
                  placeholder="Brief caption"
                  required
                  className="textfield textfield-long"
                  onChange={this.updateValue}
                  title="Maximum of 64 characters"
                />
                <ReactTags
                  ref={this.rTags}
                  tags={selectedTags}
                  delimiters={delimiters}
                  suggestions={tags}
                  classNames={rTagsClassNames}
                  onDelete={this.deleteTag}
                  onAddition={this.selectTag}
                  onInput={this.selectTag}
                  inline={true}
                  allowNew={true}
                  placeholderText={tagInputPlaceholder}
                />
                <p className="help description">{labels.helpDescription}</p>
                <ReactQuill theme="snow" value={description} onChange={this.updateDescription} />
                <Location placename={placename} coords={coords} update={this.updateLocation} mode="image" editable={true} />
                <p className="help description">{labels.helpEmbed}</p>

                <input
                  name="topic"
                  type="text"
                  value={topic}
                  minLength={2}
                  size={64}
                  placeholder="Syllabus Topic"
                  required
                  className="textfield textfield-long"
                  onChange={this.updateValue}
                  disabled={!editable}
                />
                <div className="column uri-group">
                  {imageUrlItems.map(url => <LinkItem {...url} key={url.key} update={this.updateImageLinks} />)}
                  <Icon className="plus" onClick={this.addImageUrl} title="Add new Web Resource" />
                </div>
                <div className="actions">
                  {errMsg.length > 2 && <p className="error">{errMsg}</p>}
                  {editable && (
                    <Icon className="save" onClick={this.updateImageData}>
                      <span className="label">Save</span>
                    </Icon>
                  )}
                </div>
              </div>
            ) : (
              <div className="uploader">
                {agreed && (
                  <button
                    id="upload-widget"
                    className="cloudinary-button"
                    onClick={() => this.handleAddMedia()}>
                    {uploadLabel}
                    <Icon className={uploadIcon} />
                  </button>
                )}
                {!agreed && (
                  <div className="agree-to-terms-row">
                    <p>
                      Please confirm you have read the{' '}
                      <Link to="/info/terms-and-conditions">
                        Terms and Conditions
                      </Link>{' '}
                      before submiting photographs to this site.
                    </p>
                    <input
                      id="agree-to-terms"
                      name="agreed"
                      type="checkbox"
                      className="checkbox"
                      onChange={this.agreeToTerms}
                    />
                    <label htmlFor="agree-to-terms">
                      I Agree to terms and conditions
                    </label>
                  </div>
                )}
                <div className="sign-up-row">
                  <input
                    id="sign-up"
                    name="signUp"
                    type="checkbox"
                    className="checkbox"
                    onChange={this.handleSignUp}
                    checked={signUp}
                  />
                  <label htmlFor="sign-up">
                    Sign up to our newsletter
                  </label>
                </div>
                <div className="text">{renderHTML(text)}</div>
                <div
                  className="expand-text mobile-only"
                  onClick={this.toggleTextExpanded}>
                  {toggleTextExpandedLabel}
                </div>
              </div>
            )}
          </article>
          {editUserOnly && (<Form.Group className="user-info column">
            <input
              name="displayName"
              type="text"
              value={displayName}
              minLength={2}
              size={64}
              placeholder="Display name"
              className="textfield textfield-long"
              onChange={this.updateUserFields}
            />
            <input
              name="orgName"
              type="text"
              value={orgName}
              minLength={8}
              size={64}
              placeholder="Organisation name"
              className="textfield textfield-long"
              onChange={this.updateUserFields}
            />
            <div className="column uri-group">
              {urlItems.map(url => <LinkItem {...url} key={url.key} update={this.updateLinks} />)}
              <Icon className="plus" onClick={this.addUrl} title="Add new Web Resource" />
            </div>
            <Location placename={userPlacename} coords={userCoords} update={this.updateLocation} mode="user" editable={true} />
            {editUserOnly && (
              <div className="actions">
                <Icon className="save" onClick={this.updateUser}>
                  <span className="label">Save</span>
                </Icon>
              </div>
            )}
          </Form.Group>)}
        </aside>
        <section className={mainClassNames}>
          <div className="message-pane">
            <div className="text">{message}</div>
            <i className="icon close" onClick={this.hideMessage} />
            {showDeleteButtons && (
              <div className="actions row">
                <Button onClick={this.hideMessage}><Icon className="thumbs up outline" /><span className="text-label">Keep</span></Button>
                <Button onClick={this.deleteSelectedImage}><Icon className="trash" /><span className="text-label">Delete</span></Button>
              </div>
            )}
          </div>
          <Segment.Group id="user-images" className="user-media-list" style={imageContainerStyles} horizontal>
            {numImages > 0 ? (
              images.map((img, index) => (
                <Segment
                  as="figure"
                  key={['image', index].join('-')} className={this.buildImageClassNames(img)}>
                  <div className="image-wrapper"  onClick={() => this.selectImage(img)} id={img._id}>
                    <Photo
                      img={img}
                      width={1200}
                      height={1200}
                      className="preview"
                    />
                    {img.approved && <Icon className="check" title="Approved" />}
                    <div className="status">
                      <span className="text">{img.status}</span>
                      {img.mayDelete && (
                        <Icon
                          className="trash"
                          title="Delete"
                          onClick={e => this.handleDeleteImage(img, e)}
                        />
                      )}
                    </div>
                    {!img.approved && img.needsCaption && (
                      <Icon
                        className="warning sign standalone"
                        title="Please add a caption"
                      />
                    )}
                    <Icon className="close" />
                    <figcaption>
                      {img.needsCaption ? (
                        <p className="needs-caption">
                          <Icon className="warning sign" />
                          Please add a caption
                        </p>
                      ) : (
                        <p className="caption">{img.caption}</p>
                      )}

                      {img.tags && (
                        <ul className="tags">
                          {img.tags.map((t, ti) => (
                            <li key={ti}>{t.name}</li>
                          ))}
                        </ul>
                      )}
                      <div className="description">{renderHTML(img.description)}</div>
                      {img.hasPlacename && (
                        <p className="placename">{img.location.placename}</p>
                      )}
                      <Icon className="edit" />
                    </figcaption>
                  </div>
                  <div className="handle"><Icon className="arrows alternate" /></div>
                    <button
                      id="add-embed"
                      className="add-embed-button"
                      onClick={() => this.addEmbed(img)}>
                      {this.embedEditLabel(img)}
                      <Icon className="plus" />
                    </button>
                </Segment>
              ))
            ) : (
              <Segment
                as="figure"
                className="large-placeholder"
                onClick={() => this.openWidget()}>
                <Icon className="photo" />
                <figcaption>
                  <p>No images uploaded yet.</p>
                </figcaption>
              </Segment>
            )}
          </Segment.Group>
          <Segment.Group id="user-media" className="user-media-list" horizontal>
            {hasEmbeds > 0 && embedItems.map((embed, index) => <Segment
              as="figure"
              key={embed.key}
              className={embed.className} onClick={() => this.editEmbed(index)}>
              <ReactPlayer url={embed.uri} width={embed.width} height={embed.height} controls={true} className="video-overlay" />
              <figcaption>
                <p>{embed.title}</p>
                <Icon className="edit" />
              </figcaption>
            </Segment>)}
            <div className="mask left"></div>
          </Segment.Group>
        </section>
      </Container>
    )
  }
}

export default Contribute
