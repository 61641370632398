export default {
  backendApi: '/api/',
  mediaBaseUrl: 'https://www.thegreatimagining.earth/',
  apiKey: 'drW3-Pd_8H-92mBw5kM',
  localStoreEnabled: true,
  title: 'The Great Imagining',
  strapline: 'Greener, Fairer, Wiser',
  labels: {
    photographer: 'Imagineer',
    thankyouUploading: "Thank you for uploading to the Great Imagining! Now tell us a bit more about your imagining.",
    helpDescription: "Tell us in a few words (max 2,000 characters including spaces) why you have chosen this image and how it expresses your imagining of a greener, fairer, wiser world.",
    helpEmbed: "Please add a video link to explain more about you or your organisation. Supported providers are Youtube, Vimeo, Dailymotion, Twitch and Wistia.",
    claimButtonLabel: "Claim this image",
    claimSubmitLabel: "Submit this claim",
    hasClaimedLabel: "You have already claimed this image",
    claimEmailHelp: "We need a contact e-mail, but will not publish or share it.",
    thankyouForClaiming: "Thank you claiming this image",
    sortDefault: "Highlighted first",
    sortOldest: "Oldest first",
    sortNewest: "Newest first",
  },
  requireClaimCaption: false,
  social: {
    fbAppID: '393850475554224',
    googleAppID:
      '193669454614-uhn4lg7pdi1hchc2em6fjbs2pjhpcirp.apps.googleusercontent.com',
    instagramAppID: 'e6c07deea78c4376a39930d4a45fcbbe'
  },
  captcha: {
    siteKey: "6LcRihIqAAAAAAL_DTffJ6lI4Q9zmyx2smqBaYr_",
  },	
  cloudinary: {
    widget: {
      cloudName: 'portrait-of-an-egg',
      uploadPreset: 'greatimaginings',
      sources: ['local', 'camera', 'facebook', 'dropbox', 'instagram'],
      minImageWidth: 639,
      minImageHeight: 440
    },
    baseUrl: 'https://res.cloudinary.com',
    quality: 86
  },
  gallery: {
    perLoad: 60
  },
  likes: {
    timeoutHours: 6
  },
  imageStyles: {
    large: {
      crop: 'fit',
      sizes: [
        {
          width: 640,
          height: 640,
          max: 640
        },
        {
          width: 1280,
          height: 1280,
          min: 641,
          max: 1280,
          default: true
        },
        {
          width: 1600,
          height: 1600,
          min: 1281
        }
      ]
    }
  },
  mainMenuItems: [
    // { to: '/', content: 'Home', className: 'short'},
    { to: '/about', content: 'About us', className: 'short' },
    { to: '#', content: 'Gallery of Imaginings', className: 'long' },
    { to: '/gallery', content: 'Everything', className: 'short new-line' },
    { to: '/curated', content: 'Curated', className: 'short' },
    {
      to: '/contribute',
      label: 'Contribute',
      loggedinLabel: 'My Images',
      className: 'short',
    }
  ],
  callToActions: [
    { to: '/gallery', lines: ['View', 'The Great Imagining'], classNames: 'first contribute-cta'},
    { to: '/contribute', lines: ['Share', 'YOUR IMAGINING'], classNames: 'second gallery-cta' },
  ],
  mainMenuSubNavTitle: '',
  footerData: {
    title: 'Info',
    subtitle: 'In collaboration with',
    bottomSubTitles: ['The Great Imagining 2023', 'All rights reserved'],
    menuItems: [
      { to: '/info/terms-and-conditions', content: 'Terms & Conditions' },
      { to: '/info/privacy-policy', content: 'Privacy Policy' },
      { to: '/info/acceptable-use-policy', content: 'Acceptable Use Policy' },
      { to: '/info/cookie-policy', content: 'Cookie Policy' },
      { to: '/info/licence', content: 'Licence' }
    ],
    logosEnabled: false,
    logos: [
      { uri: '/images/barbican-logo.png', title: 'The Barbican' },
      {
        uri: '/images/culture-declares-logo.jpg',
        title: 'Culture Declares'
      },
      {
        uri: '/images/fp1000tinylogo-eng.png',
        title: 'Fun Plalace'
      },
      { uri: '/images/greenhorns-logo.png', title: 'Greenhorns' },
      {
        uri: '/images/house-of-fairytales-main-logo.png',
        title: 'House of Fairytales'
      },
      {
        uri: '/images/id-osw-logo-white-ground.jpg',
        title: 'Our Shared World'
      },
      {
        uri: '/images/institute-of-imagination-square.jpg',
        title: 'Institute of Imagination'
      },
      { uri: '/images/national-trust-logo.png', title: 'national trust' },
      {
        uri: '/images/onion-collective-logo.png',
        title: 'Onion Collective'
      },
      {
        uri: '/images/reboot-the-future-logo.png',
        title: 'Reboot the future'
      },
      { uri: '/images/re-peat-logo.png', title: 'Re-peat' },
      {
        uri: '/images/royal-museums-greenwich-logo.png',
        title: 'Royal Museums Greenwich'
      },
      { uri: '/images/tate-logo.jpeg', title: 'Tate Gallery' },
      {
        uri: '/images/teach-the-future-logo.png',
        title: 'Teach the Future'
      },
      { uri: '/images/the-big-draw-logo.png', title: 'The Big Draw' }
    ],
    creditLinks: [
      { to: 'http://www.herechickychicky.com', content: 'designed by Tim Barnes' },
      { to: 'https://www.platform-3.co.uk', content: 'built by Platform3' }
    ]
  }
}
