import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import MainPhoto from '../elements/main-photo'
import config from '../../config/config'
import { addBodyClass, removeBodyClass, builImgClassNames, workHasEmbeds, notEmptyString, isIdString } from '../../modules/utils'
import ReactPlayer from 'react-player'

class FullImageSet extends React.Component {

  keydown = null
  changing = false

  state = {
    images: [],
    embeds: [],
    currMainIndex: -1,
    embedMode: false
  }

  componentWillMount = async () => {
    this.kaydown = window.addEventListener('keydown', this.handleKeyDown)
  }

  componentDidMount() {
    this.initMain()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.initMainIndex !== this.props.initMainIndex) {
      this.initMain()
    }
    /*  if (prevState.currMainIndex !== this.state.currMainIndex) {
    } */
    /*  if (prevState.currMainIndex !== this.state.currMainIndex) {
       addBodyClass('show-overlay', this.state.currMainIndex >= 0)
       const { images } = this.props.data;
       if (images instanceof Array) {
         let tgImg = images[this.state.currMainIndex]
         this.props.setMain(tgImg)
       }
     } */
  }

  componentWillUnmount() {
    if (this.keydown) {
      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }

  handleKeyDown = e => {
    switch (e.which) {
      case 39:
        if (this.state.currMainIndex < this.allImages().length - 1) {
          this.showNext()
        }
        break
      case 37:
        if (this.state.currMainIndex > 0) {
          this.showPrev()
        }
        break
      case 27:
        if (this.state.currMainIndex >= 0) {
          this.unsetMain()
        }
        break
    }
  }

  initMain = async () => {
    const images = this.allImages()
    let numImages = images.length
    if (numImages > 0) {
      const index = this.initMainIndex ? this.initMainIndex : 0;
      this.setState({
        images,
        currMainIndex: index,
        embedMode: false
      })
    }
    setTimeout(this.scrollToRelImage, 500);
    addBodyClass('show-overlay', numImages > 0)
  }

  scrollToRelImage() {
    const { location } = window;
    if (location) {
      const { pathname } = location;
      if (notEmptyString(pathname)) {
        const lastElement = pathname.split('/').pop();
        const idPart = lastElement.includes('--') ? lastElement.split('--').pop() : lastElement;
        if (isIdString(idPart)) {
          const tgId = ['image', idPart].join('--');
          const el = document.getElementById(tgId);
          if (el instanceof HTMLElement) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
      }
    }
  }

  unsetMain = () => {
    this.props.unsetMain()
    const { pictures } = this.state;
    if (pictures instanceof Array) {
      this.setState({
        pictures: pictures.map(pic => {
          return { ...pic, className: 'inactive' };
        })
      })
    }
    setTimeout(() => {
      removeBodyClass('show-overlay');
    }, 250);
  }

  allImages() {
    if (this.props.data instanceof Object) {
      const { images, user } = this.props.data;
      if (images instanceof Array) {
        return images.map(row => {
          const { className } = row;
          const cN = notEmptyString(className) ? className : 'full';
          return { ...row, user, className: cN };
        })
      }
    }
    return [];
  }

  allEmbeds() {
    if (this.props.data instanceof Object) {
      const { embeds } = this.props.data;
      if (embeds instanceof Array) {
        return embeds.map(embed => {
          const ww = window.innerWidth;
          const wh = window.innerHeight;
          const fracScreen = 0.8;
          let h = ww > wh ? wh * fracScreen : ww * fracScreen * (9 / 16);
          let w = ww > wh ? wh * (16 / 9) : ww * fracScreen;
          if (w > ww * fracScreen) {
            w = ww * fracScreen;
            h = ww * (9 / 16);
          }
          if (h > wh * fracScreen) {
            h = wh * fracScreen;
            w = h * (16 / 9);
          }
          const height = Math.floor(h);
          const width = Math.floor(w);
          return { ...embed, height, width };
        })
      }
    }
    return [];
  }

  toggleView = () => {
    const { embedMode } = this.state;
    this.setState({ embedMode: embedMode !== true });
  }

  render() {
    const { currMainIndex } = this.props;
    const { embedMode } = this.state;
    const images = this.allImages();
    const numImages = this.allImages().length;
    const hasImages = numImages > 0;

    const embeds = this.allEmbeds();
    const hasEmbeds = embeds.length > 0;
    const showMain =
      currMainIndex >= 0 && currMainIndex < numImages && (hasImages || hasEmbeds);

    const singleClass = numImages < 2 ? 'single' : 'multiple';
    const numClass = ['num-images', numImages].join('-');
    const img = images.length > 0 ? images[0] : {};
    const cls = ['image-set-container', singleClass, numClass]
    if (showMain) {
      cls.push('active')
    }
    const wrapperClasses = cls.join(' ');
    const wrapperStyleClasses = builImgClassNames(img, "main-images");
    const showImages = !embedMode;
    const showEmbeds = hasEmbeds && embedMode;
    const modeIconClasses = showEmbeds ? 'picture video-toggle' : 'video video-toggle';
    return (
      <div
        className={wrapperClasses}>
        {showMain && (
          <Segment.Group className={wrapperStyleClasses}>
            <Icon className="close" onClick={this.unsetMain} />
            {workHasEmbeds(img) && <Icon className={modeIconClasses} onClick={this.toggleView} />}
            {showImages && images.map((mainImg, si) => (
              <MainPhoto
                img={mainImg}
                sizes={config.imageStyles.large.sizes}
                key={['main-photo', si].join('-')}
                className={mainImg.className}
              />
            ))}
            {showEmbeds && embeds.map((embed, ei) => <figure className="embed" key={['embed-', embed.width, ei].join('-')}>
              <ReactPlayer url={embed.uri} width={embed.width} height={embed.height} controls={true} className="video" />
              <figcaption>{embed.title}</figcaption>
            </figure>)}
          </Segment.Group>
        )}
        <div className="backdrop" onClick={this.unsetMain}>
          <div className="back" />
        </div>
      </div>
    )
  }
}

export default FullImageSet
