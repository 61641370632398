import React from 'react'
import { Icon } from 'semantic-ui-react'
import { fetchUser } from '../../modules/api'
import { toLocal } from '../../modules/localstore'

class UserWidget extends React.Component {
  state = {
    id: 0,
    loggedIn: false,
    loginMode: '',
    role: 'none',
    status: 0,
    displayName: '',
    identifier: '',
    imageUri: '',
    outerClass: 'user-widget'
  }

  componentDidMount = async () => {
    let matchedUser = await fetchUser()
    if (matchedUser) {
      if (matchedUser.identifier) {
        this.setUserData(matchedUser, false)
      }
    }
  }

  logOut = async () => {
    const provider = this.state.loginMode
    const params = {
      id: 0,
      identifier: '',
      displayName: '',
      role: 'none',
      loggedIn: false,
      imageUri: '',
      images: []
    }
    this.setState(params)
    toLocal('current-user', { loggedIn: false })
    toLocal('user-images', [])
    this.props.registerLogin(false, provider)
  }

  // refactor and merge setUserData in login.jsx
  setUserData = (matchedUser) => {
    let agreed = false
    if (matchedUser.agreed) {
      agreed = true
    }
    let params = {
      id: matchedUser._id,
      status: matchedUser.status,
      loggedIn: true,
      identifier: matchedUser.identifier,
      loginMode: matchedUser.loginMode,
      role: matchedUser.role,
      displayName: matchedUser.displayName,
      agreed: agreed
    }
    if (matchedUser.profile) {
      if (matchedUser.profile.imageUri) {
        params.imageUri = matchedUser.profile.imageUri
      }
    }
    this.setState(params)
    this.props.registerLogin()
  }

  userData = () => {
    return this.state
  }

  render() {
    const { outerClass, displayName, imageUri, loggedIn } = this.state
    return (
      <div className={outerClass}>
        {loggedIn && (
          <span className="user-details">
            {imageUri.length > 5? (
              <img
                src={imageUri}
                className="profile thumbnail"
                alt={displayName}
                title={displayName}
              />
            ) : (
              <Icon className="user outline" title={displayName} />
            )}
            <Icon
              className="log out"
              onClick={() => this.logOut()}
              title="Log out"
            />
          </span>
        )}
      </div>
    )
  }
}

export default UserWidget
