import React, { Component } from 'react'
import { Visibility, Image, Loader } from 'semantic-ui-react'
import config from '../../config/config'
import { transformCloudinary, transformLocal } from '../../modules/utils'
import PropTypes from 'prop-types'

const { cloudinary } = config

class Photo extends Component {
  state = {
    show: false
  }

  showImage = () => {
    this.setState({
      show: true
    })
  }

  urlTransform = img => {
    switch (img.source) {
      case 'local':
        return transformLocal(img, this.props)
      default:
        return transformCloudinary(img, cloudinary, this.props)
    }
  }

  render() {
    const { img, className, title, lazy } = this.props;
    if (!this.state.show && lazy === true) {
      return (
        <Visibility as="span" onTopVisible={this.showImage}>
          <Loader active inline="centered" size="medium" />
        </Visibility>
      )
    }
    return (
      <Image
        src={this.urlTransform(img)}
        alt={title}
        className={className}
        title={title}
      />
    )
  }
}

Photo.propTypes = {
  img: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  quality: PropTypes.number,
  crop: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
}

Photo.defaultProps = {
  quality: cloudinary.quality,
  crop: 'fit',
  className: 'photo',
  title: ''
}

export default Photo
