import React from 'react'
import { Container, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import {
  saveUser,
  fetchUser,
  activateAccount,
  authenticate,
  localRegister,
  passwordReset
} from '../../modules/api'
import renderHTML from 'react-render-html'
import { applyPageContent } from '../../modules/cms'
import LoginButton from './loginButton'
import { toLocal, fromLocal } from '../../modules/localstore'
import config from '../../config/config'
import { spacedWord } from '../../modules/utils'

class Login extends React.Component {
  state = {
    id: 0,
    loggedIn: false,
    title: 'Log In',
    subtitle: 'Upload your images',
    text: '',
    mainImage: '',
    extra: '',
    hasExtra: false,
    showForm: false,
    registerMode: 0, // 1=register, 2=forgotten, 3=reset
    email: '',
    displayName: '',
    password: '',
    cpassword: '',
    hasError: false,
    errorMsgs: [],
    msg: '',
    showMsg: false,
    awaitActivation: false,
    loaded: false
  }
  _isMounted = false

  componentDidMount = async () => {
    if (!this.loggedIn) {
      this._isMounted = true
      let matchedUser = await fetchUser()
      this.parseActivation()
      if (matchedUser) {
        if (matchedUser.identifier) {
          this.setUserData(matchedUser, false)
        }
      }
      const slug = 'log-in'
      const { pages } = this.props
      applyPageContent(slug, pages).then(vars => {
        this.setState(vars)
      })
      setTimeout(() => {
        this.checkActivationStatus()
        this.setState({ loaded: true })
      }, 500)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  parseActivation = () => {
    if (window.location.search && this._isMounted) {
      let qStr = window.location.search
      if (qStr.length > 10) {
        let keyStr = qStr.split('acode=').pop()
        let resetMode = keyStr.includes('reset=1')
        let key = keyStr.split('&').shift()
        if (typeof key === 'string') {
          toLocal('activation', { status: 0, msg: '' })
          if (key.length > 9) {
            if (resetMode) {
              this.setState({
                id: key,
                registerMode: 3,
                showForm: true
              })
            } else {
              activateAccount(key).then(data => {
                this.setUserData(data);
                window.history.replaceState({}, 'Contribute', '/contribute')
              })
            }
          }
        }
      }
    }
  }

  checkActivationStatus = () => {
    if (!this.state.loggedIn && this._isMounted) {
      let aaStore = fromLocal('activation')
      let awaitActivation = false
      let awaitMsg = ''
      if (aaStore.valid) {
        if (aaStore.data) {
          if (parseInt(aaStore.data.status) > 0) {
            awaitActivation = true
            awaitMsg = aaStore.data.msg
          }
        }
      }
      this.setState({
        awaitActivation: awaitActivation,
        msg: awaitMsg,
        showMsg: awaitActivation
      })
    } else {
      toLocal('activation', { status: 0, msg: '' })
    }
  }

  setUserData = (matchedUser, loginMode) => {
    let params = {
      id: matchedUser._id,
      status: matchedUser.status,
      loggedIn: true,
      identifier: matchedUser.identifier,
      loginMode: matchedUser.loginMode,
      role: matchedUser.role,
      displayName: matchedUser.displayName
    }
    if (matchedUser.profile) {
      if (matchedUser.profile.imageUri) {
        params.imageUri = matchedUser.profile.imageUri
      }
    }
    if (loginMode) {
      let imgs = []
      if (matchedUser.images instanceof Array) {
        imgs = matchedUser.images
      }
      if (matchedUser.likes) {
        if (matchedUser.likes instanceof Array) {
          toLocal('likes', matchedUser.likes)
        }
      }
      toLocal('user-images', imgs)
    }
    if (this._isMounted) {
      this.setState(params)
      if (this.props.registerLogin) {
        this.props.registerLogin()
      }
    }
  }

  handleSocialLogin = async user => {
    if (this._isMounted) {
      this.handleSocialLoginAsync(user);
    }
  }

  handleSocialLoginAsync = async user => {
    if (user.provider) {
      let profile = {}
      let provider = ''
      if (user._provider) {
        provider = user._provider
        if (user._profile) {
          profile = user._profile
          switch (provider) {
            case 'instagram':
              if (!profile.email) {
                profile.email = profile.id + '@instagram.com'
              }
              break
          }
        }
      } else if (user.profile) {
        profile = user.profile
        provider = user.provider
      }

      let data = {
        role: 'contributor',
        loginMode: provider,
        status: 1
      }
      if (profile.name) {
        data.identifier = profile.email
        data.displayName = profile.name
        data.profile = {}
        if (profile.firstName) {
          data.profile.firstName = profile.firstName
        }
        if (profile.lastName) {
          data.profile.lastName = profile.lastName
        }
        if (profile.profilePicURL) {
          data.profile.imageUri = profile.profilePicURL
        }
        let matchedUser = await saveUser(data)
        if (matchedUser) {
          this.setUserData(matchedUser, true)
        }
      }
    }
  }

  handleSocialLoginFailure(error) {
    console.error(error)
  }

  userData = () => {
    return this.state
  }

  showLoginForm = () => {
    if (this._isMounted) {
      this.setState({ showForm: !this.state.showForm })
    }
  }

  disableActivationMode = () => {
    if (this._isMounted) {
      toLocal('activation', { status: 0, msg: '' })
      this.setState({
        registerMode: 1,
        awaitActivation: false,
        msg: ''
      })
    }
  }

  toggleRegister = forgotten => {
    let newRegMode = 0
    if (this.state.registerMode < 1) {
      newRegMode = forgotten === true ? 2 : 1
    }
    let newTitle = null
    switch (newRegMode) {
      case 1:
        newTitle = 'Register'
        break
      case 2:
        newTitle = 'Password reset'
        break
      default:
        newTitle = 'Log In'
        break
    }
    if (this._isMounted) {
      this.setState({ registerMode: newRegMode, title: newTitle })
    }
  }

  updateValue = e => {
    if (e.target.name && this._isMounted) {
      let nm = e.target.name
      let params = {}
      params[nm] = e.target.value
      this.setState(params)
    }
  }

  signIn = () => {
    const { email, password } = this.state
    if (this._isMounted) {
      this.setState({
        showMsg: false,
        hasError: false
      })
      if (email && password) {
        if (email.length > 5 && password.length > 5) {
          authenticate(email, password).then(user => {
            if (user.valid) {
              this.setUserData(user, true)
            } else {
              if (user.msg && this._isMounted) {
                if (user.msg.length > 3) {
                  this.setState({
                    showMsg: true,
                    errorMsgs: [user.msg],
                    hasError: true
                  })
                }
              }
            }
          })
        }
      }
    }
  }

  validPassword = password => {
    if (typeof password === 'string') {
      return (
        password.length > 7 &&
        /^[a-z0-9_;.,-]+$/i.test(password) &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password)
      )
    }
    return false
  }

  handleMailSend = (data, email, forgottenMode) => {
    if (email) {
      if (!data.email) {
        data.email = email
      }
    }
    if (data.mail && this._isMounted) {
      let messageText = forgottenMode
        ? 'A password reset email has been sent to'
        : 'An activation email has been sent to'
      this.setState({
        showError: false,
        errorMsgs: [],
        registerMode: 0,
        showMsg: true,
        msg: `${messageText} ${data.identifier}`,
        awaitActivation: true
      })
      toLocal('activation', {
        status: 1,
        msg: `${messageText} ${data.identifier}`
      })
    }
  }

  register = () => {
    if (this._isMounted) {
      this.setState({
        hasError: false,
        errorMsgs: []
      })
    }
    const {
      id,
      email,
      displayName,
      password,
      cpassword,
      registerMode
    } = this.state
    if (this._isMounted) {
      this.setState({
        showMsg: false,
        hasError: false
      })
    }
    const msgs = []
    const forgottenMode = registerMode === 2
    const resetMode = registerMode === 3
    if (!resetMode) {
      if (email.length < 6 || !/^[^@ ]+@\w+/.test(email)) {
        msgs.push('Please enter a valid email address')
      }
    }
    if (!forgottenMode) {
      if (!resetMode) {
        if (displayName.length < 4) {
          msgs.push('Please enter a display name with at least 4 characters')
        }
      }
      if (!this.validPassword(password)) {
        msgs.push(
          'Please enter a valid password containing 8 characters with at least 1 lower case letter, 1 upper case letter and 1 numeral'
        )
      } else if (password !== cpassword) {
        msgs.push('Your passwords do not match')
      }
    }
    if (msgs.length < 1 && this._isMounted) {
      switch (registerMode) {
        case 2:
          passwordReset({
            email
          }).then(d => {
            if (d.valid) {
              this.handleMailSend(d, email, resetMode)
            }
          })
          break
        case 3:
          passwordReset({
            password,
            key: id
          }).then(d => {
            let msg = null
            if (d.valid) {
              msg = 'Your password has been reset'
              toLocal('current-user', d);
              this.setUserData(d, true);
              window.history.replaceState({}, 'Contribute', '/contribute')
            } else if (d.expired) {
              msg = 'Your password reset code has expired'
            } else if (!d.active) {
              msg = 'Your account is no longer active'
            } else {
              msg =
                'An account associated this email address is not on our system'
            }
            if (msg) {
              this.setState({
                showMsg: true,
                msg: msg,
                hasError: true
              })
            }
          })
          break
        case 1:
          localRegister({
            identifier: email,
            displayName: displayName,
            password: password
          }).then(d => {
            if (d.valid) {
              this.handleMailSend(d, email, false)
            } else {
              let msg = ''
              if (d.msg) {
                msg = d.msg
              }
              if (msg.length > 3 && this._isMounted) {
                this.setState({
                  showMsg: true,
                  msg: msg,
                  hasError: true
                })
              }
            }
          })
      }
    } else if (this._isMounted) {
      this.setState({
        hasError: true,
        errorMsgs: msgs
      })
    }
  }

  render() {
    let {
      title,
      subtitle,
      text,
      mainImage,
      extra,
      hasExtra,
      showForm,
      registerMode,
      hasError,
      errorMsgs,
      showMsg,
      msg,
      awaitActivation,
      loaded
    } = this.state
    const { showLogin } = this.props
    const showLoginWidget = showLogin && loaded && !this.loggedIn;
    let toggleRegisterLabel =
      registerMode > 0 ? 'Show sign-in form' : 'Register a new account'

    let cls = ['login-form']
    if (showForm) {
      cls.push('mail-login-mode')
    }
    const topLoginClassNames = cls.join(' ')

    cls = ['custom', 'login-form']
    let submitClassName = ''
    let submitMethod = this.signIn
    let submitLabel = 'Log in'
    let showEmailField = true
    let showPasswordField = true
    let showConfirmPassword = false
    switch (registerMode) {
      case 1:
        cls.push('registration-mode')
        submitMethod = this.register
        submitClassName = 'signup'
        showConfirmPassword = true
        submitLabel = 'Register'
        break
      case 2:
        cls.push('forgotten-mode')
        submitMethod = this.register
        submitClassName = 'send'
        showPasswordField = false
        submitLabel = 'Send reminder'
        break
      case 3:
        cls.push('reset-mode')
        submitMethod = this.register
        submitClassName = 'send'
        showPasswordField = true
        showConfirmPassword = true
        showEmailField = false
        title = 'Update password'
        submitLabel = 'Reset password'
        break
      default:
        cls.push('login-mode')
        submitMethod = this.signIn
        submitClassName = 'sign-in'
        submitLabel = 'Log in'
        break
    }
    const customLoginClassNames = cls.join(' ')
    cls = ['message']
    if (hasError) {
      cls.push('error')
    }
    const messageClassNames = cls.join(' ')
    const titleSpacedWords = title.split(' ').map(spacedWord);
    return (
      <Container className="main-content">
        <section className="main-area two-thirds">
          <h2 className="subtitle">{subtitle}</h2>
          {renderHTML(mainImage)}
          {hasExtra && <div className="extra">{renderHTML(extra)}</div>}
        </section>
        <aside className="text-content">
          <h1 className="title">{titleSpacedWords.map((word, wi) => (<span className="word" key={['login-word', wi].join('-')}>{word}</span>))}</h1>
          <div className={topLoginClassNames}>
            <p className="label">Log in with:</p>
            {showLoginWidget && (
              <p className="login-buttons">
                <LoginButton
                  provider="google"
                  className="google"
                  title="Log in in with Google"
                  appId={config.social.googleAppID}
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.handleSocialLoginFailure}
                  key="google"
                />
                <LoginButton
                  provider="facebook"
                  appId={config.social.fbAppID}
                  className="facebook"
                  title="Log in with Facebook"
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={e => this.handleSocialLoginFailure(e)}
                  key="facebook"
                />
                <Icon
                  className="mail"
                  onClick={this.showLoginForm}
                  key="mail"
                  title="Sign in with your email address. Registration is required."
                />
              </p>
            )}
          </div>
          {showForm && (
            <div className={customLoginClassNames}>
              {awaitActivation ? (
                <div className="form-inner actions">
                  <strong
                    className="register"
                    onClick={this.disableActivationMode}>
                    Register with another email
                  </strong>
                </div>
              ) : (
                <div className="form-inner">
                  {showEmailField && (
                    <input
                      type="email"
                      name="email"
                      className="textfield textfield-medium"
                      placeholder="your@email.address"
                      onChange={this.updateValue}
                    />
                  )}
                  {registerMode === 1 && (
                    <input
                      type="text"
                      name="displayName"
                      className="textfield textfield-medium"
                      placeholder="Display name"
                      onChange={this.updateValue}
                    />
                  )}
                  {showPasswordField && (
                    <input
                      type="password"
                      name="password"
                      className="password"
                      placeholder="password"
                      onChange={this.updateValue}
                    />
                  )}
                  {showConfirmPassword && (
                    <input
                      type="password"
                      name="cpassword"
                      className="password"
                      placeholder="confirm password"
                      onChange={this.updateValue}
                    />
                  )}
                </div>
              )}
              {hasError && (
                <ul className="error">
                  {errorMsgs.map((msg, mi) => (
                    <li key={mi}>{msg}</li>
                  ))}
                </ul>
              )}
              {!awaitActivation && (
                <div className="actions">
                  <div className="submit" onClick={submitMethod}>
                    <Icon className={submitClassName} /> {submitLabel}
                  </div>
                  <strong className="register" onClick={this.toggleRegister}>
                    {toggleRegisterLabel}
                  </strong>
                  <p
                    className="forgotten"
                    onClick={() => this.toggleRegister(true)}>
                    Forgotten your password?
                  </p>
                </div>
              )}
            </div>
          )}
          {showMsg && <p className={messageClassNames}>{msg}</p>}
          <article>{renderHTML(text)}</article>
        </aside>
      </Container>
    )
  }
}

Login.propTypes = {
  pages: PropTypes.array,
  registerLogin: PropTypes.func,
  showLogin: PropTypes.bool,
  loggedIn: PropTypes.bool
}

Login.defaultProps = {
  pages: [],
  showLogin: false,
  loggedIn: false
}

export default Login
