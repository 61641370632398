import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MenuListItem extends Component {
  isActive = path => {
    if (typeof path === 'string') {
      let secPath = path.split('/').find(part => part.length > 1)
      if (!secPath) {
        secPath = ''
      }
      const section = '/' + secPath
      return this.props.current === section
    }
    return false
  }

  render() {
    const { to, className, children, isLink } = this.props
    const linkClasses = to.replace(/^\//, '').replace(/\/+/g, '-')
    const linkClass = linkClasses.length > 1? linkClasses : 'home';
    const cls = this.isActive(to) ? [linkClass, className, 'active'] : [linkClass, className, 'inactive'];
    const itemClasses = cls.join(' ');
    
    return (
      <li className={itemClasses}>
        {isLink? (<Link to={to} className={linkClass}>
          {children}
        </Link>) : (<h3>{children}</h3>)}
        
      </li>
    )
  }
}

export default MenuListItem
