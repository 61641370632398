import React, { Component } from 'react'
import MenuListItem from './menu-list-item'
import { Icon, Loader } from 'semantic-ui-react'

class MenuList extends Component {
  state = {
    items: [],
    showLoader: false,
  }

  async componentDidMount() {
    this.processItems()
  }

  processItems = () => {
    const menuItems = this.props.items.map(item => {
      item.hasContent = typeof item.content === 'string'
      item.isLink = item.to.startsWith('/')
      item.hasLabel = typeof item.label === 'string'
      item.hasLoggedinLabel = typeof item.loggedinLabel == 'string'
      item.hasIcon = typeof item.icon === 'string'
      return item
    })
    this.setState({ items: menuItems })
  }

  triggerSignup() {
    const nullSuffix = "path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = "MCPopupClosed=;" + nullSuffix;
    document.cookie = "MCPopupSubscribed=" + nullSuffix;
    setTimeout(() => {
      if (window.$mcSite instanceof Object) {
        if (window.$mcSite.popup_form instanceof Object) {
          const { settings } = window.$mcSite.popup_form;
          if (settings instanceof Object) {
            const { base_url, list_id, uuid } = settings;
            window.dojoRequire(["mojo/signup-forms/Loader"], function (L) { L.start({ "baseUrl": base_url, "uuid": uuid, "lid": list_id, "uniqueMethods": true }); });
            this.setState({ showLoader: true });
            setTimeout(() => {
              this.setState({ showLoader: false });
            }, 6000);
          }
        }
      }
    }, 125)
  }

  render() {
    const { current, className, signup } = this.props
    const addSignUp = signup === true;
    const { items, showLoader } = this.state
    const hasItems = items instanceof Array && items.length > 0
    const cls = ['menu']
    if (className) {
      cls.push(className)
    }

    const listClasses = cls.join(' ')
    return (
      <ul className={listClasses}>
        {hasItems &&
          items.map((item, index) => (
            <MenuListItem to={item.to} current={current} key={index} className={item.className} isLink={item.isLink}>
              {item.hasContent && item.content}
              {item.hasLabel && (
                <span
                  className={item.hasLoggedinLabel ? `logged-out` : `label`}>
                  {item.label}
                </span>
              )}
              {item.hasLoggedinLabel && (
                <span className="logged-in">{item.loggedinLabel}</span>
              )}
              {item.hasIcon && <Icon className={item.icon} />}
            </MenuListItem>
          ))}
        {addSignUp && <li className="signup" onClick={() => this.triggerSignup()}>
          <span className="text-action">Newsletter Signup</span>
          {showLoader &&
            <Loader active inline="centered" size="medium" />
          }
        </li>}
      </ul>
    )
  }
}

export default MenuList
