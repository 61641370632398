const localStorageSupported = () => {
  try {
    return 'localStorage' in window && window['localStorage'] !== null
  } catch (e) {
    return false
  }
}

export const toLocal = (key, data) => {
  if (localStorageSupported()) {
    let ts = Date.now() / 1000
    let sd = ts + ':'
    if (typeof data === 'object') {
      sd += 'obj:' + JSON.stringify(data)
    } else {
      sd += 'sca:' + data
    }
    localStorage.setItem(key, sd)
  }
}

export const fromLocal = (key, maxAge) => {
  let ts = Date.now() / 1000
  let obj = {
    expired: true,
    valid: false
  }
  if (!maxAge) {
    maxAge = 60 * 60
  }
  if (localStorageSupported()) {
    let data = localStorage.getItem(key)
    if (data) {
      let parts = data.split(':')
      if (parts.length > 2) {
        obj.ts = parts.shift()
        obj.ts = obj.ts - 0
        obj.type = parts.shift()
        obj.data = parts.join(':')

        if (obj.type === 'obj') {
          obj.data = JSON.parse(obj.data)
        }
        let latestTs = obj.ts + maxAge
        if (ts <= latestTs) {
          obj.expired = false
          obj.valid = true
        }
      }
    }
  }
  return obj
}

export const clearLocal = (key, fuzzy) => {
  const keys = Object.keys(localStorage)
  let rgx = null
  if (fuzzy === true) {
    rgx = new RegExp('^' + key)
  } else {
    fuzzy = false
  }
  for (let i = 0; i < keys.length; i++) {
    let k = keys[i]
    if (fuzzy) {
      if (rgx.test(k)) {
        localStorage.removeItem(k)
      }
    } else if (k === key || key === 'all') {
      switch (k) {
        case 'current-user':
        case 'user-images':
          break
        default:
          localStorage.removeItem(k)
          break
      }
    }
  }
}
