import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import { Swipeable } from 'react-swipeable'
import MainPhoto from '../elements/main-photo'
import config from '../../config/config'
import { addBodyClass } from '../../modules/utils'

class MainImageSet extends React.Component {

  keydown = null
  changing = false

  state = {
    images: [],
    numImages: 0,
    pictures: [],
    currMainIndex: -1
  }

  componentWillMount = async () => {
    this.kaydown = window.addEventListener('keydown', this.handleKeyDown)
  }

  componentDidMount() {
    this.initMain()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.initMainIndex !== this.props.initMainIndex) {
      this.initMain()
    }
    if (prevState.currMainIndex !== this.state.currMainIndex) {
      addBodyClass('show-overlay', this.state.currMainIndex >= 0)
      if (
        this.state.currMainIndex >= 0 &&
        this.state.currMainIndex < this.props.images.length
      ) {
        let tgImg = this.props.images[this.state.currMainIndex]
        this.props.setMain(tgImg)
      }
    }
  }

  componentWillUnmount() {
    if (this.keydown) {
      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }

  handleKeyDown = e => {
    switch (e.which) {
      case 39:
        if (this.state.currMainIndex < this.props.images.length - 1) {
          this.showNext()
        }
        break
      case 37:
        if (this.state.currMainIndex > 0) {
          this.showPrev()
        }
        break
      case 27:
        if (this.state.currMainIndex >= 0) {
          this.unsetMain()
        }
        break
    }
  }

  initMain = async () => {
    let { images, initMainIndex } = this.props
    let numImages = images.length
    if (initMainIndex >= 0 && initMainIndex < numImages) {
      let index = parseInt(initMainIndex)
      let startIndex = index > 0 && numImages > 1 ? index - 1 : 0
      let numMainImgs = numImages > 2 ? 3 : numImages
      let endIndex = startIndex + numMainImgs - 1
      let lastIndex = numImages - 1
      if (endIndex > lastIndex) {
        let diff = endIndex - lastIndex
        endIndex = lastIndex
        startIndex -= diff
      }
      let imgs = []
      for (let i = startIndex; i <= endIndex; i++) {
        imgs.push(images[i])
      }
      let defIndex = 1

      if (index === 0) {
        defIndex = 0
      } else if (index === lastIndex) {
        defIndex = numMainImgs - 1
      }
      imgs = imgs.map((img, si) => {
        img.className = si === defIndex ? 'active' : 'inactive'

        return img
      })
      this.setState({
        pictures: imgs,
        currMainIndex: index
      })
    }
    addBodyClass('show-overlay', initMainIndex >= 0)
  }

  addToMain = (index, dir) => {
    let { images } = this.props
    if (index >= 0 && index < images.length) {
      let img = images[index]
      let { pictures } = this.state
      img.className = 'inactive'
      if (dir > 0) {
        pictures.push(img)
      } else {
        pictures.unshift(img)
      }
      this.setState({
        pictures: pictures
      })
    }
  }

  showNext = prev => {
    let dir = prev === true ? -1 : 1
    let { pictures, currMainIndex } = this.state
    const lastIndex = pictures.length - 1
    const currIndex = pictures.findIndex(img => img.className === 'active')
    const currImg = pictures[currIndex]
    const targetIndex = currIndex + dir
    if (currImg && pictures.length > 0) {
      if (lastIndex >= 0) {
        if (!this.changing) {
          this.changing = true
          const imgs = pictures.map((img, si) => {
            img.className = si === targetIndex ? 'active' : 'inactive'
            return img
          })
          this.setState({
            pictures: imgs,
            currMainIndex: currMainIndex + dir
          })
          let endImg = dir > 0 ? pictures[lastIndex] : pictures[0]
          let nextIndex =
            this.props.images.findIndex(img => img._id === endImg._id) + dir
          setTimeout(() => {
            this.addToMain(nextIndex, dir)
            setTimeout(() => {
              this.changing = false
            }, 500);
          }, 300)
          if (dir === 1 && this.state.currMainIndex > (this.props.images.length - 3) && this.props.loadMore instanceof Function) {
            this.props.loadMore()
          }
        }
      }
    }
  }

  showPrev = () => {
    this.showNext(true)
  }

  showNextIcon = () => {
    return this.state.currMainIndex < this.props.images.length - 1
  }

  showPrevIcon = () => {
    return this.state.currMainIndex > 0
  }

  unsetMain = () => {
    this.props.unsetMain()
    addBodyClass('show-overlay', false)
    const { pictures } = this.state;
    if (pictures instanceof Array) {
      this.setState({
        pictures: pictures.map(pic => {
          return { ...pic, className: 'inactive' };
        })
      })
    }
  }

  render() {
    const { pictures, currMainIndex } = this.state
    const showMain =
      currMainIndex >= 0 && currMainIndex < this.props.images.length
    let cls = ['main-images-container']
    if (showMain) {
      cls.push('active')
    }
    const wrapperClasses = cls.join(' ')
    return (
      <div
        className={wrapperClasses}>
        {showMain && (
          <Segment.Group className="main-images">
            <Icon className="close" onClick={this.unsetMain} />
            {this.showPrevIcon() && (
              <Icon className="arrow left" onClick={this.showPrev} />
            )}
            {pictures.map((mainImg, si) => (
              <MainPhoto
                img={mainImg}
                sizes={config.imageStyles.large.sizes}
                key={si}
                className={mainImg.className}
              />
            ))}
            {this.showNextIcon() && (
              <Icon className="arrow right" onClick={this.showNext} />
            )}
          </Segment.Group>
        )}
        <Swipeable onSwipedLeft={this.showNext} onSwipedRight={this.showPrev} className="swipeable"></Swipeable>
        <div className="backdrop" onClick={this.unsetMain}>
          <div className="back" />
        </div>
      </div>
    )
  }
}

export default MainImageSet
