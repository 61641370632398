import React, { Component } from 'react'
import { Form, Button } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
import { fromLocal, toLocal } from '../../modules/localstore'
import { notEmptyString, validEmail } from '../../modules/utils';
import { saveClaimedImage } from '../../modules/api';
import config from '../../config/config';

class ClaimForm extends Component {

  state = {
    name: '',
    email: '',
    orgName: '',
    caption: '',
    submitted: false,
    maySubmit: false,
    ok: false,
    showCaptcha: false,
    claimedRows: [],
    claimed: false
  }

  recaptchaRef = null;

  componentDidMount() {
    this.recaptchaRef = React.createRef();
    const stored = fromLocal("claim_details", 7 * 24 * 60 * 60);
    if (stored instanceof Object) {
      if (!stored.expired) {
        if (stored.data instanceof Object) {
          const { name, email, orgName } = stored.data;
          this.setState({ name, email, orgName });
          setTimeout(() => {
            const maySubmit = this.validate();
            this.setState({ maySubmit, showCaptcha: maySubmit });
          }, 250);
        }
      }
    }
    setTimeout(this.syncClaims, 500)
  }

  syncClaims = () => {
    const cdStored = fromLocal("claimed", 60 * 60);
    if (cdStored.data instanceof Array) {
      const { imgId } = this.props;
      const claimedRows = cdStored.data.filter(r => r instanceof Object && r.imgId);
      const claimedItem = claimedRows.find(r => r.imgId.toString() === imgId.toString());
      const claimed = claimedItem instanceof Object;
      this.setState({ claimedRows, claimed });
      if (claimed) {
        const { name, email, orgName, caption } = claimedItem;
        this.setState({ name, email, orgName, caption });
      }
    }
  }

  validate = () => {
    const { name, caption, email } = this.state;
    const requireCaption = config.requireCaption === true;
    const captionOK = !requireCaption || notEmptyString(caption);
    return captionOK && notEmptyString(name) && validEmail(email);
  }

  setOk = (e) => {
    this.setState({ ok: true });
    this.submit();
  }

  updateFields = (e) => {
    const { target } = e;
    if (target instanceof Object) {
      const { name, value } = target;
      const maySubmit = this.validate();
      this.setState({  [name]: value, maySubmit, showCaptcha: maySubmit  });
      setTimeout(this.storeState, 125);
      this.validate();
    }
  }

  storeState = () => {
    const { name, email, orgName, caption } = this.state;
    const { imgId } = this.props;
    toLocal("claim_details", { name, email, orgName, caption, imgId });
  }

  submit = () => {
    const {imgId, handleSubmit } = this.props;
    const { name, email, orgName, caption, ok, claimedRows } = this.state;
    this.setState({submitted: false });
    
    if (this.validate()) {
      if (ok) {
        const payload = { name, email: email.trim(), orgName, caption: caption.trim() };
        saveClaimedImage(imgId, payload).then(result => {
          if (result.valid) {
            if (handleSubmit instanceof Function) {
              this.setState({ submitted: true });
              setTimeout(() => {
                handleSubmit(result);
                
              }, 5000);
              const stored = fromLocal("last-updated", 24 * 3600);
              if (stored.data instanceof Object) {
                const updated = new Date().toISOString();
                const storeData = {...stored.data, updated, refresh: true, refreshRemote: true };
                toLocal('last-updated', storeData);
                const clIndex = claimedRows.findIndex(r => r.imgId.toString() === imgId);
                if (clIndex < 0) {
                  claimedRows.push({ imgId, ... payload})
                } else {
                  claimedRows[clIndex] = { imgId, ... payload};
                }
                toLocal('claimed', claimedRows);
              }
            }
          }
        });
      } else {
        this.setState({ showCaptcha: true });
      }
    }
    
  }
  
  render() {
    const { imgId } = this.props
    const { name, orgName, email, caption, submitted, maySubmit, showCaptcha, claimed } = this.state
    const { siteKey } = config.captcha;
    const {labels } = config;
    const claimLabel = claimed? labels.hasClaimedLabel : labels.claimButtonLabel;
    return (
      <Form.Group className="claim-form column" data-id={imgId}>
            <input
              name="name"
              type="text"
              value={name}
              minLength={2}
              size={64}
              required={true}
              placeholder="Your name"
              className="textfield textfield-long"
              onChange={this.updateFields}
            />
            <input
              name="email"
              type="email"
              required={true}
              value={email}
              minLength={8}
              size={48}
              placeholder="Your email"
              className="textfield textfield-long"
              onChange={this.updateFields}
            />
            <p className="claim-help">{labels.claimEmailHelp}</p>
            <input
              name="orgName"
              type="text"
              value={orgName}
              minLength={2}
              size={64}
              placeholder="Organisation, school or college name"
              className="textfield textfield-long"
              onChange={this.updateFields}
            />
            <textarea
              name="caption"
              type="text"
              minLength={8}
              cols={48}
              rows={3}
              placeholder="Caption"
              defaultValue={caption}
              className="textfield textfield-long"
              onChange={this.updateFields}></textarea>
        {claimed && <p>{labels.hasClaimedLabel}</p>}
        {maySubmit && <Button className="claim-submit" onClick={this.submit}>{labels.claimButtonLabel}</Button>}
        {showCaptcha && <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey={siteKey}
          onChange={this.setOk}
        />}
        {submitted && <p>{labels.thankyouForClaiming}</p>}
      </Form.Group>
    )
  }
}

export default ClaimForm
