import React from 'react'
import { Container } from 'semantic-ui-react'
import { fetchCuration } from '../../modules/api'
import MainImageSet from '../elements/main-image-set'
import Grid from '../elements/grid'
import { isIdString, notEmptyString, spacedWord } from '../../modules/utils'
import { fromLocal } from '../../modules/localstore'

class FolderGallery extends React.Component {
  mounted = false

  state = {
    slug: '',
    title: 'Folder',
    text: '',
    bio: '',
    user: {
      displayName: null
    },
    mainImage: {},
    hasProfileUri: false,
    images: [],
    mainIndex: -1,
    num: 0
  }

  componentWillMount() {
    if (this.props.location.pathname) {
      let parts = this.props.location.pathname.split('/')
      let mainImageId = ''
      if (parts.length > 1) {
        let slug = parts.pop()
        if (isIdString(slug) && parts.length > 1) {
          if (!isIdString(parts[parts.length - 1])) {
            slug = parts.pop();
            mainImageId = slug;
          }
        }
        let section = parts.pop()
        switch (section) {
          case 'curation':
          case 'curations':
          case 'curated':
          case 'folders':
          case 'folder':
          case 'hall-of-fame':
            this.load(slug, mainImageId)
            break
        }
      }
    }
  }

  load = async (slug = '', mainImageId = '') => {
    this.setState({
      slug: slug
    })
    const forceRefreshStored = fromLocal('refresh', 5 * 60);
    let refresh = false
    if (!forceRefreshStored.expired) {
      refresh = true;
    }
    fetchCuration(slug, 'folder', refresh).then(d => {
      const sd = {}
      sd.title = d.title
      if (d.valid && d.images.length > 0) {
        sd.images = d.images.map((img, index) => {
          img.index = index
          return img
        })
        sd.num = d.num
      }
      if (d.hasProfileUri) {
        sd.mainImage = d.mainImage
        sd.hasProfileUri = d.hasProfileUri
      }
      if (d.user) {
        sd.user = d.user
      }
      sd.text = d.text
      if (d.bio) {
        sd.bio = d.bio
      }
      this.setState(sd)
      if (mainImageId.length > 5) {
        let tgImg = sd.images.find(im => im._id === mainImageId)
        if (tgImg) {
          this.setMain(tgImg)
        }
      }
    })
  }

  setMain = async targetImg => {
    let { images, slug } = this.state
    let index = images.findIndex(im => im._id === targetImg._id);
    if (index >= 0) {
      this.setState({
        mainIndex: index
      })
      const slugStr = notEmptyString(slug) ? slug : 'curated';
      const base = slugStr.includes('folder') ? '/folders' : '/curated';
      this.props.history.push([base, slug, targetImg._id].join('/'));
    }
  }

  unsetMain = () => {
    let { slug, mainIndex } = this.state
    if (mainIndex >= 0) {
      this.setState({
        mainIndex: -1
      });
      const slugStr = notEmptyString(slug) ? slug : 'curated';
      const base = slugStr.includes('folder') ? '/folders' : '/curated';
      this.props.history.push([base, slug].join('/'))
    }
  }

  render() {
    const {
      title,
      text,
      user,
      images,
      mainIndex,
    } = this.state
    const cls = ['main-content']
    if (mainIndex >= 0) {
      cls.push('show-main')
    }
    const wrapperClasses = cls.join(' ')
    const strTitle = notEmptyString(title) ? title : "--";
    const titleSpacedWords = strTitle.split(' ').map(spacedWord);
    return (
      <Container className={wrapperClasses}>
        <h1 className="title" title={user.displayName}>{titleSpacedWords.map((word, wi) => (
          <span className="word" key={['curated-gallery-title-word', wi].join('-')}>{word}</span>
        ))}</h1>
        <div className="inner-content padded-content">
          <article className="text-content">{text}</article>
        </div>
        <Grid
          images={images}
          restart={true}
          setMain={this.setMain.bind(this)}
        />
        <MainImageSet
          images={images}
          initMainIndex={mainIndex}
          unsetMain={this.unsetMain}
          setMain={this.setMain}
        />
      </Container>
    )
  }
}

export default FolderGallery
