import React from 'react'
import SocialLogin from 'react-social-login'
import { Icon } from 'semantic-ui-react'
 
const Button = ({ children, triggerLogin, ...props }) => (
  <Icon onClick={triggerLogin} {...props}>
    { children }
  </Icon>
)
 
export default SocialLogin(Button);