import React from 'react'
import { Route, Switch, Link, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Icon } from 'semantic-ui-react'
import { animateScroll } from 'react-scroll'
import PropTypes from 'prop-types'
import Gallery from '../gallery/index'
import Page from '../page/index'
import Contribute from '../contribute/index'
import CurationsList from '../curations/index'
import CurationGallery from '../curations/gallery'
import FolderGallery from '../curations/folder'
import UserWidget from '../user/widget'
import MenuList from '../elements/menu-list'
import Footer from '../elements/footer'
import Login from '../user/login'
import {
  fetchUser,
  fetchPages,
  checkVersion,
  lastUpdated
} from '../../modules/api'
import config from '../../config/config'
import { clearLocal, toLocal } from '../../modules/localstore'

const ContributeContainer = (props) => {
  const { loggedIn, pages, showLogin, registerLogin } = props;
  if (loggedIn) {
    return <Contribute pages={pages} {...props} />
  } else {
    return <Login
      registerLogin={registerLogin}
      showLogin={showLogin}
      pages={pages}
      loggedIn={loggedIn}
      {...props}
    />
  }
}

class App extends React.Component {
  version = 25

  section = 'home'

  subsection = ''

  state = {
    loggedIn: false,
    wrapperClass: 'wrapper',
    footerData: {},
    hasFooter: false,
    pages: [],
    showMenu: false,
    showLogin: true,
    updateCheckCount: 0
  }

  _isMounted = false

  updateCheckInterval = -1;

  updateCheckTimeOut = -1;

  static contextTypes = {
    router: PropTypes.object
  }

  componentWillMount = async () => {
    checkVersion(this.version)
  }

  componentDidMount = async () => {
    this.handleLocationChange(this.context.router.history.location)
    this.unlisten = this.context.router.history.listen(
      this.handleLocationChange
    )
    this._isMounted = true
    await this.loadPages();

    this.loadFooterData();
    this.props.history.listen((location, action) => {
      this.setState({ showMenu: false })
    })
    this.updateCheckTimeOut = setTimeout(this.lastUpdated, 30 * 1000)
    this.updateCheckInterval = setInterval(this.lastUpdated, 30 * 60 * 1000)
  }

  loadPages = async (refresh = false) => {
    const pageData = await fetchPages(refresh)
    if (pageData.valid) {
      this.setState({
        pages: pageData.items
      })
    }
  }

  componentWillUnmount() {
    this.unlisten()
    this._isMounted = false
    clearInterval(this.updateCheckInterval)
    clearTimeout(this.updateCheckTimeOut);
  }

  loadFooterData = async () => {
    if (config.footerData.logos instanceof Array) {
      config.footerData.logos = config.footerData.logos.map(img => {
        img.className = img.uri
          .split('/')
          .pop()
          .split('.')
          .shift()
        return img
      })
    }
    this.setState({
      footerData: config.footerData,
      hasFooter: true
    })
    const matchedUser = await fetchUser()
    if (matchedUser.identifier) {
      this.setState({
        loggedIn: true
      })
    }
  }

  handleLocationChange = location => {
    let section = 'home'
    let sub1 = ''
    let toTop = true
    if (location.pathname.length > 2) {
      let locParts = location.pathname.replace(/^\//, '').split('/')
      section = locParts.shift()
      if (locParts.length > 0) {
        sub1 = locParts.shift()
      }
    }
    switch (section) {
      case 'gallery':
        if (this.section === section || sub1.length > 2) {
          toTop = false
        }
        break
      case 'curated':
      case 'folders':
        if (this.section === section && sub1 === this.subsection) {
          toTop = false
        }
        break
    }
    if (toTop) {
      let sTop = window.scrollY / window.innerHeight
      let dur = Math.pow(sTop, 0.5) * window.innerHeight
      if (dur < 250) {
        dur = 250
      } else if (dur > 1500) {
        dur = 1500
      }
      animateScroll.scrollToTop({
        duration: dur,
        smooth: true
      });
      this.section = section
      this.subsection = sub1
    }
  }

  toggleMenu = () => {
    if (this._isMounted) {
      const sm = this.state.showMenu !== true
      this.setState({
        showMenu: sm
      })
    }
  }

  closeMenu = e => {
    if (e.target) {
      if (this.state.showMenu && this._isMounted) {
        let tn = e.target.tagName.toLowerCase()
        switch (tn) {
          case 'li':
          case 'i':
            break
          default:
            this.setState({ showMenu: false })
            break
        }
      }
    }
  }

  registerLogin = (inMode, provider) => {
    if (this._isMounted) {
      if (inMode !== false) {
        inMode = true
      }
      if (this.props.location.search && inMode === true) {
        if (/rslCallback=instagram/i.test(this.props.location.search)) {
          this.props.history.replace(this.props.location.pathname)
        }
      }
      if (!inMode && provider === 'instagram') {
        this.props.history.replace('/gallery')
      }
      let st = {
        loggedIn: inMode
      }
      if (!inMode && this.state.loggedIn === false) {
        st.showLogin = false
        setTimeout(() => {
          this.setState({
            showLogin: true
          })
        }, 1000)
      }

      this.setState(st)
    }
  }

  lastUpdated = () => {
    let { updateCheckCount } = this.state
    if (updateCheckCount < 12 && this._isMounted) {
      let fetchAll = updateCheckCount < 1
      this.setState({ updateCheckCount: updateCheckCount + 1 })
      lastUpdated(fetchAll).then(d => {
        if (d.refresh) {
          this.clearImageCaches()
        }
      })
    }
  }

  clearImageCaches = () => {
    clearLocal('image-list', true)
    clearLocal('front-images', true)
    clearLocal('media-by-user', true)
    clearLocal('curation', true)
  }

  clearMainCaches = () => {
    clearLocal('image-list', true)
    clearLocal('front-images', true)
    clearLocal('media-by-user', true)
    clearLocal('tag-list', true)
    clearLocal('pages', true)
    clearLocal('posts', true)
    toLocal('refresh', true);
    this.loadPages(true);
  }

  render() {
    const {
      loggedIn,
      wrapperClass,
      footerData,
      pages,
      showMenu,
      showLogin,
      hasFooter
    } = this.state
    const { title, strapline } = config;
    const titleParts = title.trim().split(/\s+/);
    const { location } = this.props
    const current = location.pathname
    let cls = [wrapperClass]
    const pathParts = current.replace(/^\//, '').split('/')
    const pathSlug = pathParts.join('-')
    const alias = pathSlug.length > 1 ? pathSlug : 'home';
    const numParts = pathParts.length
    const section = pathParts.shift()
    if (section !== pathSlug) {
      cls.push(section)
    }
    cls.push(alias)
    switch (section) {
      case 'gallery':
      case 'curated':
      case 'folders':
        cls.push('full-width')
        cls.push('black-bg')
        break
      case 'news':
        if (numParts > 1) {
          cls.push('right-col-offset')
        } else {
          cls.push('full-width')
        }
        break
      default:
        cls.push('right-col-offset')
        break
    }
    if (loggedIn) {
      cls.push('logged-in')
    }
    const wrapperClassNames = cls.join(' ')
    const cls2 = ['inner-wrapper']
    if (showMenu) {
      cls2.push('show-menu')
    }
    const straplineFormatted = strapline.split(',').join(' • ')
    const headerClassNames = cls2.join(' ')
    // const topMainNavItems = config.mainMenuItems.filter(nv => nv.line === 1)
    const mainNavItems = config.mainMenuItems
    return (
      <div className={wrapperClassNames}>
        <div className="outer-frame">
          <header className={headerClassNames} onClick={this.closeMenu}>
            <figure className="logo">
              <figcaption>
                <h1 className="main-title">{titleParts.map((part, pi) => (
                  <span className="word" key={['main-title-word', pi].join('-')}>{part}</span>
                ))}</h1>
                <h2 className="strapline">{straplineFormatted}</h2>
              </figcaption>
              <Link to="/" className="home-link"></Link>
              <div className="reset-cache" onClick={this.clearMainCaches}>♺</div>
            </figure>
            <nav className="main-nav">
              <Icon className="bars" onClick={this.toggleMenu} />
              <MenuList
                items={mainNavItems}
                current={current}
                className="main-menu top"
                loggedIn={loggedIn}
              />
              {loggedIn && (
                <UserWidget registerLogin={this.registerLogin.bind(this)} />
              )}
            </nav>
          </header>

          <main className="inner-wrapper">
            <Switch loggedIn={loggedIn}>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/gallery" /> }
              />
              <Route exact path="/gallery/:id?/:sub?" component={Gallery} />
              <Route
                exact
                path="/about"
                component={() => <Page slug="about" pages={pages} />}
              />
              <Route
                exact
                path="/curated"
                component={() => (
                  <CurationsList slug="curated" pages={pages} />
                )}
              />
              <Route
                exact
                path="/curated/:title/:id?"
                component={CurationGallery}
              />
              <Route
                exact
                path="/folders"
                component={() => (
                  <CurationsList slug="folders" pages={pages} />
                )}
              />
              <Route
                exact
                path="/folders/:title/:id?"
                component={FolderGallery}
              />
              <Route exact path="/news/:date/:title" component={Page} />
              <Route
                path="/contribute"
                component={() => <ContributeContainer registerLogin={this.registerLogin.bind(this)}
                  showLogin={showLogin}
                  pages={pages}
                  loggedIn={loggedIn} />} />
              <Route
                exact
                path="/info/:title"
                component={() => <Page pages={pages} slug="--info" />}
              />
            </Switch>
          </main>
          {hasFooter && <Footer data={footerData} current={current} />}
        </div>
      </div>
    )
  }
}

export default withRouter(App)
