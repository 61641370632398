import axios from 'axios'
import moment from 'moment'
import config from '../config/config'
import { fromLocal, toLocal, clearLocal } from './localstore'
import { isNumeric, matchInt, notEmptyString, validUKPostcode, extractPostcode, validUrl } from './utils'

const postData = async (path, params, putMode) => {
  let result = {}
  let func = putMode !== true ? axios.post : axios.put
  await func(`${config.backendApi}${path}`, params, {
    headers: {
      apikey: config.apiKey
    }
  }).then(res => {
    result = res
  })
  return result
}

const putData = async (path, params) => {
  return postData(path, params, true)
}

const fetchData = async (path, deleteMode) => {
  let result = {}
  let func = deleteMode !== true ? axios.get : axios.delete
  await func(`${config.backendApi}${path}`, {
    headers: {
      apikey: config.apiKey
    }
  }).then(res => {
    result = res
  })
  return result
}

const fetchContent = async (path, refresh) => {
  if (refresh === true && typeof path === 'string') {
    path += '?refresh=1'
  }
  return fetchData(path, false)
}

const deleteData = async path => {
  return fetchData(path, true)
}

export const fetchImages = async (filter, start, limit, refresh) => {
  let data = {
    items: [],
    num: 0
  }
  start = matchInt(start, 0)
  limit = matchInt(limit, 100)
  if (!filter) {
    filter = '-'
  }
  let checkLocal = start < 1
  let cKey = ''
  if (refresh !== true) {
    refresh = false
  }
  if (checkLocal) {
    cKey = `image-list-${filter}-${start}-${limit}`
    const stored = fromLocal(cKey, 600)
    if (stored.valid && config.localStoreEnabled && stored.data.num > 0) {
      data = stored.data
    }
  }
  if (data.num < 1) {
    await fetchContent(`works/${filter}/${start}/${limit}`, refresh).then(
      res => {
        if (res.data.items) {
          if (res.data.items instanceof Array) {
            data = res.data
          }
        }
      }
    )
    if (data.items instanceof Array && checkLocal) {
      toLocal(cKey, data)
    }
  }
  return data
}

export const fetchImage = async idRef => {
  let data = {
    valid: false
  }
  await fetchContent(`work/${idRef}`).then(res => {
    if (res.data._id) {
      data = res.data
      data.valid = true
    }
  })
  return data
}

export const fetchCurations = async (mode = 'curation', refresh = false) => {
  let data = {
    items: [],
    num: 0
  }
  const isFolderMode = ['folder', 'folders'].includes(mode);
  const method = isFolderMode ? 'folders' : 'curations';
  const cKey = isFolderMode ? 'folder-list' : `curation-list`
  const stored = fromLocal(cKey, 600)
  if (stored.valid && config.localStoreEnabled && stored.data.num > 0) {
    data = stored.data
  }
  if (data.num < 1) {
    await fetchContent(method, refresh).then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data
        }
      }
    })
    if (data.items instanceof Array) {
      toLocal(cKey, data)
    }
  }
  return data
}

export const fetchCuration = async (idRef, mode = 'curation', refresh = false) => {
  let data = {
    images: [],
    num: 0,
    valid: false
  }
  if (refresh !== true) {
    refresh = false
  }
  let cKey = `curation-${idRef}`
  const stored = fromLocal(cKey, 600)
  if (stored.valid && config.localStoreEnabled && stored.data.num > 0) {
    data = stored.data
  }
  const isFolderMode = ['folder', 'folders'].includes(mode);
  const method = isFolderMode ? 'folder' : 'curation';
  if (data.num < 1) {
    await fetchContent(`${method}/${idRef}`, refresh).then(res => {
      if (res.data.images) {
        if (res.data.images instanceof Array) {
          data = res.data
          data.valid = true
        }
      }
    })
    if (data.valid) {
      toLocal(cKey, data)
    }
  }
  return data
}

const syncUpdateStats = (data, sd) => {
  let ks = ['pagesUpdated', 'postsUpdated', 'numCurations']
  for (let i = 0; i < ks.length; i++) {
    let k = ks[i]
    if (!data[k]) {
      if (sd[k]) {
        data[k] = sd[k]
      }
    }
  }
}

export const lastUpdated = async otherContent => {
  let data = {
    valid: false
  }
  let prefix = otherContent === true ? '' : 'work/'
  await fetchData(prefix + 'last-updated').then(d => {
    if (d.data) {
      data = d.data
      const stored = fromLocal('last-updated', 5 * 60)
      if (stored.valid) {
        const sd = stored.data
        data.refresh = false
        if (otherContent !== true) {
          syncUpdateStats(data, sd)
        }
        if (sd.updated) {
          let storedTs = moment(sd.updated).unix()
          let lastTs = moment(data.updated).unix()
          data.refresh = lastTs > storedTs + 60
        }
      }
      toLocal('last-updated', data)
    }
  })
  return data
}
 
export const fetchTags = async () => {
  let data = {
    items: [],
    num: 0
  }
  const stored = fromLocal('tag-list', 86400)
  if (stored.valid && config.localStoreEnabled) {
    data = stored.data
  } else {
    await fetchData('tags').then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data
        }
      }
    })
    if (data.items) {
      toLocal('tag-list', data)
    }
  }
  return data
}

/*
export const fetchArtists = async (start, limit) => {
  let data = {
    items: [],
    num: 0
  }
  start = matchInt(start, 0)
  limit = matchInt(limit, 400)
  const cKey = `artist-list-${start}-${limit}`
  const stored = fromLocal(cKey, 7200)
  if (stored.valid && config.localStoreEnabled) {
    data = stored.data
  } else {
    await fetchData(`artists/${start}/${limit}`).then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data
        }
      }
    })
    if (data.items) {
      toLocal(cKey, data)
    }
  }
  return data
} */

export const fetchSearchWords = async (getNew = false) => {
  let data = {
    items: [],
    num: 0
  }
  const cKey = `search-words`
  const stored = getNew ? { valid: false, data: null } : fromLocal(cKey, 7200)
  if (stored.valid && config.localStoreEnabled && stored.data instanceof Array && stored.data.length > 0) {
    data = stored.data
  } else {
    const refreshStr = getNew ? "?refresh=1" : "";
    await fetchData(`build-search/simple${refreshStr}`).then(res => {
      if (res.data instanceof Array) {
        data.items = res.data
      }
    })
    if (data.items.length > 0) {
      toLocal(cKey, data)
    }
  }
  return data
}

export const authenticate = async (identifier, password) => {
  let data = {
    valid: false
  }
  let params = {
    identifier,
    password
  }
  await postData('user/authenticate', params).then(response => {
    if (response.data) {
      const user = response.data
      if (user.identifier) {
        if (user.status > 0) {
          const cKey = 'current-user'
          data = user
          data.valid = true
          toLocal(cKey, data)
        }
      }
      if (user.msg) {
        data.msg = user.msg
      }
    }
  })
  return data
}

export const localRegister = async params => {
  let data = {
    valid: false,
    msg: 'unmatched'
  }
  await postData(`register-local`, params).then(res => {
    if (res.data) {
      data = res.data
      if (data.identifier) {
        if (data.status > 0) {
          data.msg = 'OK'
        } else {
          data.msg = 'Account disabled'
        }
      } else {
        data.valid = false
      }
    }
  })
  return data
}

export const passwordReset = async params => {
  let data = {
    valid: false,
    msg: 'unmatched'
  }
  await postData(`password-reset`, params).then(res => {
    if (res.data) {
      if (res.data.identifier) {
        data = res.data
        if (data.status > 0) {
          data.msg = 'OK'
        } else {
          data.msg = 'Account disabled'
        }
      }
    }
  })
  return data
}

export const saveUser = async user => {
  let data = {
    valid: false,
    msg: 'unmatched'
  }
  await postData('user/save', user).then(res => {
    if (res.data) {
      if (res.data.identifier) {
        data = res.data
        if (data.status > 0) {
          data.valid = true
          data.msg = 'OK'
          const cKey = 'current-user'
          toLocal(cKey, data)
        } else {
          data.msg = 'Account disabled'
        }
      }
    }
  })
  return data
}

export const editUser = async (userId, params) => {
  let data = {
    valid: false
  }
  await postData(`user/edit/${userId}`, params).then(res => {
    if (res.data) {
      if (res.data.identifier) {
        data = res.data
        if (data.status > 0) {
          data.valid = true
          const cKey = 'current-user'
          toLocal(cKey, data)
        }
      }
    }
  })
  return data
}

export const postImage = async (info, mode, callback) => {
  let data = {
    valid: false
  }
  switch (info.format) {
    case 'jpg':
    case 'jpeg':
      info.mime = 'image/jpeg'
      break
    case 'png':
      info.mime = 'image/png'
      break
  }
  switch (mode) {
    case 'upload':
      data = {
        uri: info.secure_url,
        mimetype: info.mime,
        filesize: info.bytes,
        width: info.width,
        height: info.height,
        caption: info.caption,
        description: '',
        tags: [],
        source: 'cloudinary',
        status: 'uploaded',
        user: info.user
      }
      break
  }
  await putData('work/save', data).then(res => {
    if (res.data) {
      if (res.data.uri) {
        handleImageData(res.data, true, callback)
      }
    }
  })
  return data
}

export const deleteImage = async id => {
  let data = {
    valid: false
  }
  await deleteData(`work/delete/${id}`).then(res => {
    if (res.data) {
      data = res.data
      data.id = id
      data.valid = true
    }
  })
  return data
}

const handleImageData = (data, isNew, callback) => {
  let stored = fromLocal('user-images', 86400)
  let imgs = []
  if (stored.valid) {
    if (stored.data instanceof Array) {
      imgs = stored.data
    }
  }
  if (data.uri) {
    if (isNew) {
      imgs.unshift(data)
    } else {
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i]._id === data._id) {
          imgs[i] = data
        }
      }
    }
    toLocal('user-images', imgs)
    if (callback) {
      setTimeout(() => {
        callback({ success: true })
      }, 100)
    }
  }
}

export const updateImage = async (id, params, callback) => {
  let data = {
    valid: false
  }
  await postData(`work/edit/${id}`, params).then(res => {
    if (res.data) {
      if (res.data.uri) {
        handleImageData(res.data, false, callback)
      }
    }
  })
  return data
}

export const saveImageWeight = async (userId = '', ids, isTop) => {
  let data = {
    valid: false
  }
  if (notEmptyString(userId) && ids instanceof Array) {
    const payload = {
      userId,
      ids,
      isTop
    }
    await postData(`image/save-weight`, payload).then(res => {
      if (res.data) {
        data.valid = true;
      }
    })
  }
  return data
}


export const saveProfile = async user => {
  let data = {
    valid: false
  }
  await postData('user/save-profile', user).then(res => {
    if (res.data) {
      if (res.data.identifier) {
        data = res.data
        data.valid = true
      }
    }
  })
  return data
}

export const fetchUser = async () => {
  const cKey = 'current-user'
  const stored = fromLocal(cKey, 7200)
  let data = {
    valid: false
  }
  if (stored.valid) {
    data = stored.data
  }
  return data
}

export const storeUser = (user) => {
  const cKey = 'current-user'
  if (user instanceof Object) {
    const keys = Object.keys(user);
    if (keys.length > 3 && keys.includes('_id') && keys.includes('identifier')) {
      toLocal(cKey, user)
    }
  }
}

export const updateEmbeds = async (embeds = []) => {
  const user = await fetchUser();
  if (user instanceof Object && embeds instanceof Array) {
    if (notEmptyString(user.identifier, 5)) {
      const edited = { ...user, embeds };
      toLocal('current-user', edited);
    }
  }
}

const getUpdatedTs = key => {
  let stored = fromLocal('last-updated', 60 * 60)
  if (stored.valid) {
    if (stored.data.hasOwnProperty(key)) {
      return moment(stored.data[key]).unix()
    }
  }
}

const getLastModifiedTs = items => {
  if (items instanceof Array && items.length > 0) {
    let sorted = items.sort((a, b) => a.modified - b.modified)
    if (sorted.length > 0) {
      let item = sorted[sorted.length - 1]
      if (item.modified) {
        return moment(item.modified).unix()
      }
    }
  }
}

const cmsDataNeedsRefresh = (key, items) => {
  let ts = getUpdatedTs(key)
  let refresh = false
  if (ts) {
    let lastTs = getLastModifiedTs(items)
    if (ts > lastTs + 60) {
      refresh = true
    }
  }
  return refresh
}

const fetchContentItems = async (cKey, refreshAnyway = false) => {
  const stored = fromLocal(cKey, 86400)
  let data = {
    valid: false
  }
  let fetchNew = true
  let refresh = refreshAnyway
  let isStored = false
  if (stored.valid && config.localStoreEnabled) {
    data = stored.data
    refresh = refreshAnyway || cmsDataNeedsRefresh('pagesUpdated', data.items)
    fetchNew = refresh
    isStored = true
  }
  if (fetchNew) {
    await fetchContent(cKey, refresh).then(res => {
      if (res.data.items) {
        if (res.data.items instanceof Array) {
          data = res.data
          isStored = false
        }
      }
    })
  }
  if (!isStored) {
    if (data.items) {
      toLocal(cKey, data)
    }
  }
  return data
}

export const fetchPages = async (refresh = false) => {
  return await fetchContentItems(`pages`, refresh)
}

export const fetchPosts = async (refresh = false) => {
  return await fetchContentItems(`posts`, refresh)
}

export const activateAccount = async key => {
  let data = {
    valid: false
  }
  let params = {
    activationCode: key
  }
  await postData('activate', params).then(res => {
    if (res.data) {
      data = res.data
      data.profile = {}
      toLocal('current-user', data)
    }
  })
  return data
}

const buildTopImageQueryString = (page = 1, perPage = 60, filterKey = 'all') => {
  const hasFilter = notEmptyString(filterKey) && filterKey !== 'all';
  return hasFilter ? '?filter=' + filterKey : page > 1 ? '?page=' + page + '&perPage=' + perPage : '';
}

export const getTopImages = async (refresh = false, page = 0, perPage = 60, filterKey = '', mode = "front", refreshRemote, sort = '') => {
  const hasFilter = notEmptyString(filterKey, 1);
  const filterRef = hasFilter ? filterKey : 'all';
  const frontMode = mode !== "top";
  const baseKey = frontMode ? 'front-images' : 'top-images';
  const start = page * perPage; 
  const ckParts = [baseKey, start, perPage, filterRef];
  let hasSort = false;
  switch (sort) {
    case 'oldest':
    case 'newest':
      ckParts.push(sort);
      hasSort = true;
      break
  }
  const cKey = ckParts.join('-');
  const stored = refresh ? { valid: false } : fromLocal(cKey, 60 * 60);
  if (stored.valid) {
    return stored.data;
  } else {
    const pp = perPage > 10 ? perPage : 60;
    const uriParts = [baseKey, filterRef, start, perPage];
    if (hasSort) {
      uriParts.push(sort);
    }
    const uri = frontMode ? uriParts.join('/') : baseKey + buildTopImageQueryString(page, pp, filterRef);
    const response = await fetchContent(uri, refreshRemote);
    const { data } = response;
    if (data instanceof Object) {
      data.valid = true;
      toLocal(cKey, data);
      return data;
    }
  }
  return { valid: false, items: [] };
}

export const getNextTopImages = async (page = 0, perPage = 60, mode = "front") => {
  const data = await getTopImages(false, page, perPage);
  if (data instanceof Object && data.items instanceof Array) {
    return data.items;
  } else {
    return false;
  }
}

/* export const clearBackCaches = async () => {
  const uri = 'clear-image-list-caches';
  const response = await fetchContent(uri);
  const { data } = response;
  if (data instanceof Object) {
    return data;
  }
  return { valid: false };
} */


export const getUserMedia = async (userRef = '', refresh = false) => {
  const cKey = ['media-by-user', userRef].join('--');
  let stored = refresh ? { valid: false } : fromLocal(cKey, 60 * 60);
  if (stored.valid) {
    return stored.data;
  } else {
    const uri = ['media-by-user', userRef].join('/');
    const response = await fetchContent(uri);
    const { data } = response;
    if (data instanceof Object) {
      toLocal(cKey, data);
      return data;
    }
  }
  return { valid: false };
}

export const saveUserData = async (userId = '', fields = null) => {
  let data = {
    valid: false
  }
  if (notEmptyString(userId, 12) && fields instanceof Object) {
    const keys = Object.keys(fields);
    const params = {};
    if (keys.includes('urls') && fields.urls instanceof Array) {
      params.urls = fields.urls.filter(url => url instanceof Object);
    }
    if (keys.includes('orgName') && notEmptyString(fields.orgName)) {
      params.orgName = fields.orgName.trim()
    }
    if (keys.includes('displayName') && notEmptyString(fields.displayName)) {
      params.displayName = fields.displayName.trim()
    }
    if (keys.includes('location') && fields.location instanceof Object) {
      params.location = fields.location
    }
    if (Object.keys(params).length > 0) {
      await postData('user/edit/' + userId, params).then(res => {
        if (res.data) {
          data = res.data
          data.profile = {}
          toLocal('current-user', data)
        }
      })
    }
  }

  return data
}

export const saveEmbed = async (userId, uri = '', title = '', imgId = '', mode = 'image', index = -1) => {
  if (notEmptyString(title) && validUrl(uri)) {
    const payload = {
      uri,
      title,
      imgId,
      mode,
      index
    }
    const response = await postData('user/edit-embed/' + userId, payload);
    if (response.status >= 200 && response.status < 300) {
      const { data } = response
      const cKey = 'current-user'
      const stored = fromLocal(cKey);
      if (stored.valid) {
        const user = stored.data;
        if (user instanceof Object) {
          const { embeds } = user;
          if (embeds instanceof Array) {
            toLocal(cKey, { ...user, embeds })
          }
        }
      }
      return data;
    }
  }
  return { valid: false };
}

export const deleteEmbed = async (userId, index = -1) => {
  if (notEmptyString(userId) && isNumeric(index)) {
    const uri = ['user/delete-embed', userId, index].join('/');
    const response = await deleteData(uri);
    if (response.status >= 200 && response.status < 300) {
      const { data } = response
      return data;
    }
  }
  return { valid: false };
}

export const likeImage = async imgId => {
  let user = await fetchUser()
  let params = {
    imgId: imgId,
    uid: null
  }
  let data = {
    valid: false
  }
  if (user) {
    if (user._id) {
      params.uid = user._id
    }
  }
  await postData('like', params).then(res => {
    data = res.data
    let stored = fromLocal('likes')
    let lks = []
    if (data.num) {
      let lkIndex = -1
      if (stored.valid) {
        if (stored.data instanceof Array) {
          lks = stored.data
        }
      }
      lkIndex = lks.findIndex(item => item.imgId === imgId)
      if (lkIndex >= 0) {
        lks[lkIndex].num = data.num
      } else {
        lks.push({
          imgId: imgId,
          num: data.num
        })
      }
      toLocal('likes', lks, 6 * 60 * 60)
    }
  })
  return data
}

export const saveClaimedImage = async (imgId, payload) => {
  const result = {valid: false };
  await postData('save-artist/' + imgId, payload).then(res => {
    const { data } = res
    if (data instanceof Object) {
      if (data.valid) {
        result.valid = true;
      }
    }
    
  })
  return result
}

export const checkVersion = async version => {
  let secs = 7 * 24 * 60 * 60
  let stored = fromLocal('version', secs)
  let cv = 0
  if (stored.data) {
    cv = parseFloat(stored.data)
    if (isNaN(cv)) {
      cv = 0
    }
  }

  let clear = cv < version
  if (clear) {
    clearLocal('all')
    toLocal('version', version)
  }
  return clear
}

export const matchLocation = async (str = '') => {
  let pn = typeof str === 'string' ? str.trim() : '';
  const method = validUKPostcode(str) ? 'pc-match' : 'geo-search';
  if (method === 'pc-match') {
    if (pn.includes('(') && pn.includes(')')) {
      pn = pn.split('(').pop().split(')').shift();
    } else {
      pn = extractPostcode(pn);
    }
  }
  const url = [method, pn].join('/');
  const response = await fetchContent(url);
  let result = { valid: false, method, search: str };
  if (response instanceof Object) {
    const { data } = response;
    if (data instanceof Object) {
      result = { ...data, method, search: str };
    }
  }
  return result;
}
