import React from 'react'
import { Segment } from 'semantic-ui-react'
import Picture from './picture'
import PropTypes from 'prop-types'
import config from '../../config/config'

class PictureList extends React.Component {

  setMain = img => {
    this.props.setMain(img)
  }

  render() {
    let { images } = this.props
    let numImages = this.props.images.length
    return (
      <Segment.Group id="column-images" horizontal>
        {numImages > 0 &&
          images.map(img => (
            <Segment
              as="figure"
              key={img._id}
              onClick={() => this.setMain(img)}
              className={`index-${img.index}`}>
              <Picture img={img} sizes={config.imageStyles.large.sizes} />
              <figcaption>
                <p className="caption">{img.caption}</p>
                <p className="full-name">{img.user.displayName}</p>
              </figcaption>
            </Segment>
          ))}
      </Segment.Group>
    )
  }
}

PictureList.propTypes = {
  images: PropTypes.array,
  setMain: PropTypes.func
}

PictureList.defaultProps = {
  images: []
}

export default PictureList
