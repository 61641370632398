import React from 'react'
import { Container, Icon } from 'semantic-ui-react'
/* import { WithContext as ReactTags } from 'react-tag-input' */
import ReactTags from 'react-tag-autocomplete'
import {
  getTopImages,
  getUserMedia,
  fetchSearchWords
} from '../../modules/api'
import FullImageSet from '../elements/full-image-set'
import Grid from '../elements/grid'
import config from '../../config/config'
import { fromLocal, toLocal } from '../../modules/localstore'
import { spacedWord, notEmptyString, removeBodyClass, sanitize, isIdString, extractEmbeds } from '../../modules/utils'

class Gallery extends React.Component {
  timeout = null

  interval = null

  scrollTs = 0

  prevScrollY = 0

  state = {
    title: 'Everything',
    images: [],
    numImages: 0,
    slideData: {
      valid: false,
      images: [],
      embeds: [],
    },
    numEmbeds: 0,
    loadOffset: 0,
    loading: false,
    total: 0,
    page: 0,
    perPage: 60,
    mixedTags: [],
    numTags: 0,
    numArtists: 0,
    activeFilter: 'all',
    filterName: 'All',
    restart: false,
    mainIndex: -1,
    selectedTags: [],
    lastUpdated: null,
    refresh: false,
    sort: 'default'
  }

  constructor(props) {
    super(props);
    this.rTags = React.createRef();
  }

  componentWillMount = async () => {
    this.load()
    const results = await fetchSearchWords();
    if (results.items instanceof Array) {
      const mixedTags = results.items.map(row => {
        const { slug, mode, num } = row;
        const strNum = num > 1 ? `  (${num})` : '';
        const name = `${row.name} [${mode}]${strNum}`
        return { ...row, name, id: slug, mode };
      });
      const numTags = mixedTags.filter(row => row.mode === 'tags').length;
      const numArtists = mixedTags.filter(row => row.mode === 'artist').length;
      this.setState({ mixedTags, numTags, numArtists });
    }
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('scroll', this.handleScroll)
    setTimeout(this.showMainOverlay, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleLocationChange(this.props.location);
    }
  }

  handleLocationChange = location => {
    if (location instanceof Object) {
      const { pathname } = location;
      if (notEmptyString(pathname)) {
        const parts = pathname.split('/');
        if (parts.length > 1) {
          const last = parts.pop();
          if (!/\w+/.test(last)) {
            this.unsetMain(false);
          }
        }
      }
    }
  }


  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('scroll', this.handleScroll)
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (this.interval) {
      clearTimeout(this.interval)
    }
  }

  showMainOverlay = () => {
    if (window) {
      const { location } = window;
      if (location) {
        const { pathname } = location;
        if (pathname) {
          const parts = pathname.substring(1).split('/').filter(p => notEmptyString(p, 2));
          if (parts.length > 1) {
            const { images, page, artists } = this.state;
            if (images.length > 0) {
              const lastPart = parts.pop();
              if (notEmptyString(lastPart, 3)) {
                const imgId = lastPart.includes('--') ? lastPart.split('--').pop() : lastPart;
                if (isIdString(imgId)) {
                  const imgIndex = images.findIndex(img => img._id.toString() === imgId);
                  if (imgIndex >= 0) {
                    this.setMain(images[imgIndex]);
                  } else if (page < 10) {
                    setTimeout(this.load, 750);
                    setTimeout(this.showMainOverlay, 2250);
                  }
                } else {
                  if (artists instanceof Array && artists.length > 0) {
                    const row = artists.find(row => sanitize(row.displayName) === lastPart);
                    if (row instanceof Object) {
                      const img = images.find(imr => imr.user._id === row._id);
                      if (img instanceof Object) {
                        this.setMain(img, true);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  handleSort = (e) => {
    const { target } = e;
    if (target instanceof HTMLElement) {
      let sort = 'default';
      if (target.classList.contains('down')) {
        sort = 'newest';
      } else if (target.classList.contains('up')) {
        sort = 'oldest';
      }
      this.setState({sort});
      setTimeout(() => {
        this.load(true);
      }, 250);
    }
  }

  numImages = () => {
    const { images } = this.state;
    return images instanceof Array ? images.length : 0;
  }

  numFolders = () => {
    const { images } = this.state;
    return images instanceof Array ? images.filter(im => im.type === 'folder').length : 0;
  }

  total = () => {
    const { total } = this.state;
    return typeof total === "number" ? this.state.total : 0;
  }

  load = async (restart = false, update = false) => {
    let { loadOffset, page, perPage, activeFilter, sort } = this.state
    const filterKey = notEmptyString(activeFilter) && activeFilter !== 'all' ? activeFilter : '';
    const forceRefreshStored = fromLocal('refresh', 5 * 60);
    let refresh = false
    let refreshRemote = false
    
    if (!forceRefreshStored.expired) {
      refresh = true;
      if (forceRefreshStored.data) {
        refreshRemote = true
      }
    }
    const restartMode = restart === true;
    if (restartMode) {
      loadOffset = 0
    }
    let stored = fromLocal('last-updated', 60 * 60)

    
    if (stored.valid) {
      if (stored.data.refresh) {
        refresh = true
        if (stored.data.refreshRemote) {
          refreshRemote = true
          const newData = { ...stored.data, refresh: false, refreshRemote: false };
          toLocal('refresh', newData);
        }
      }
    }
    const data = {
      items: [],
      lastUpdated: 0
    }
    let perLoad = config.gallery.perLoad
    if (update) {
      loadOffset = 0
    }
    const numLoaded = this.numImages();

    const numImagesLoaded = page * perPage - this.numFolders();
    const totalAvailable = this.total();
    const loadMore = numLoaded < 30 || totalAvailable < 2 || totalAvailable > numImagesLoaded || restartMode;
    const pageNum = restartMode ? 0 : numLoaded > 30 && page >= 0 ? page + 1 : 0;
    if (loadMore) {
      this.setState({ loading: true })
      const result = await getTopImages(
        refresh,
        pageNum,
        perPage,
        filterKey,
        "front",
        refreshRemote,
        sort
      )
      const { items, total } = result;
      if (items instanceof Array) {
        const newImages = items.map(row => {
          const type = notEmptyString(row.type) ? row.type : 'user';
          return { ...row, type }
        });
        const images = restartMode ? newImages : [...this.state.images, ...newImages];
        const numImages = images.length;
        this.setState({
          images,
          numImages,
          total,
          page: result.page,
          loadOffset: loadOffset + perLoad,
          restart: restartMode
        })
        if (data.lastUpdated) {
          this.setState({
            lastUpdated: data.lastUpdated
          })
        }
      }
    }
    if (restart === true) {
      this.prevScrollY = 0;
      setTimeout(() => {
        this.setState({ restart: false, refresh: false })
      }, 10)
    }
    setTimeout(() => {
      this.setState({ loading: false })
    }, 500)
  }

  handleScroll = e => {
    if (window.scrollY) {
      if (window.innerHeight) {
        let sY = window.scrollY
        let frac = sY / window.innerHeight


        if (sY > this.prevScrollY + 32 && (frac > 0.25 || sY > 200)) {
          this.prevScrollY = sY
          let tgPane = document.querySelector('#masonry-grid')

          if (tgPane) {
            let loadMoreHeight = window.scrollY + window.innerHeight * 3;

            if (tgPane.clientHeight < loadMoreHeight) {
              this.loadMore()
            }
          }
        } else if (this.state.total > this.state.numImages) {
          if (this.scrollTs < 1 || e.timeStamp > this.scrollTs + 500) {
            this.scrollTs = e.timeStamp
            this.prevScrollY = 200
          }
        }
      }
    }
    return true
  }

  handleTimeout = e => {
    this.loadMore()
  }

  loadMore = () => {
    if (this.state.total > this.state.numImages) {
      if (!this.state.loading) {
        this.load()
      } else {
        setTimeout(() => {
          this.setState({ loading: false })
        }, 250);
      }
    } else {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  setMain = async (targetImg, loadMultiple = false) => {
    const { _id, user, type } = targetImg;
    const isFolder = type.startsWith('folder') && notEmptyString(targetImg.slug);
    let slug = isFolder ? [targetImg.slug, _id].join('/') : 'work--' + _id;
    const hasUser = user instanceof Object && notEmptyString(user._id) && notEmptyString(user.displayName);
    const loadSingleImage = !isFolder && loadMultiple !== true;

    if (hasUser && !isFolder) {
      slug = [sanitize(user.displayName), _id].join('/');
    }
    if (isFolder) {
      const newUrl = ['/folders', slug].join('/');
      this.props.history.push(newUrl)
    } else if (loadSingleImage) {
      if (user instanceof Object) {
        const embedItems = extractEmbeds(targetImg);
        const slideData = {
          type,
          valid: true,
          images: [targetImg],
          embeds: embedItems,
          user
        }
        this.setState({ slideData: slideData, mainIndex: 0 })
        this.props.history.push('/gallery/' + slug)
      }
    } else if (hasUser) {
      getUserMedia(targetImg.user._id).then(data => {
        if (data instanceof Object) {
          const { images } = data;
          if (images instanceof Array) {
            this.setState({ slideData: { ...data, user }, mainIndex: 0 })
            this.props.history.push('/gallery/' + slug)
          }
        }
      })
    }
  }

  setGroup = async (userId = '') => {
    const { pathname } = window.location;
    const newPath = '/gallery/group-' + userId;
    if (newPath !== pathname) {
      this.props.history.push(newPath);
    }
  }

  setGroupSlug = async (slug = '') => {
    const { pathname } = window.location;
    const newPath = '/gallery/user/' + slug;
    if (newPath !== pathname) {
      this.props.history.push(newPath);
    }
  }

  handleKeyDown = e => {
    switch (e.key.toLowerCase()) {
      case "escape":
        this.unsetMain(true)
        break
    }
  }

  unsetMain = (resetPath = true) => {
    let { mainIndex } = this.state
    removeBodyClass('show-overlay');
    if (mainIndex >= 0) {
      this.setState({
        mainIndex: -1
      })
      if (resetPath) {
        this.props.history.push('/gallery')
      }
    }
  }

  /* filterByTag(tag) {
    if (tag.slug !== this.state.activeFilter) {
      this.setState({
        activeFilter: tag.slug,
        filterName: tag.name
      })
      setTimeout(() => this.load(true), 100)
    }
  }

  filterByUser(user, reload = true) {
    if (user instanceof Object) {
      let ref = 'u--' + user.id
      if (ref !== this.state.activeFilter) {
        this.setState({
          activeFilter: ref,
          filterName: user.name
        })

        if (reload) {
          setTimeout(() => this.load(true), 100);
        }
        this.setGroup(user.id);
      }
    }
  } */

  /* filterByUserSlug(slug = '') {
    const { artists } = this.state
    if (artists instanceof Array && notEmptyString(slug)) {
      const nameRgx = new RegExp('^' + slug.replace(/-/g, '.*'), 'i');
      const row = artists.find(artist => nameRgx.test(artist.displayName));
      if (row instanceof Object) {
        const ref = 'u--' + row._id;
        const user = {
          type: 'artist',
          id: row._id,
          name: row.displayName
        }
        this.filterByUser(user);
        this.selectTag(user);
        this.setState({
          activeFilter: ref,
          filterName: user.name
        });
      }
    }
  } */

  matchFilterPrefix(mode = '') {
    switch (mode) {
      case 'artist':
        return 'u';
      case 'claimed':
        return 'a';
      case 'caption':
        return 'c';
      case 'orgName':
        return 'o';
      default:
        return '-';
    }
  }

  selectTag = row => {
    if (row instanceof Object) {
      const { selectedTags, mixedTags } = this.state
      const tgs = selectedTags instanceof Array ? selectedTags : [];
      tgs.push(row);
        const filters = tgs.filter(tg => tg instanceof Object).map(tg => {    
          const tag = mixedTags.find(mt => mt.id === tg.id);
          if (tag instanceof Object) {
            const { id, mode } = tag;
            const prefix = this.matchFilterPrefix(mode);
            return prefix !== '-'? [prefix, id].join('--') : id;
          }
          return '-';
        }).filter(f => f.length > 1);
        const activeFilter = filters.join(',');
        const filterName = tgs.map(tg => tg.name.split('[').shift().trim()).join(', ');
        this.setState({
          activeFilter,
          filterName,
          selectedTags: tgs
        })
        setTimeout(() => {
          this.load(true)
          this.setInputMarginLeft()
        }, 100);
    }
  }

  setInputMarginLeft(reset = false) {
    const inputEl = document.querySelector('.react-tags__search-input');
    const selectedContainer = document.querySelector('.react-tags__selected');
    if (inputEl instanceof HTMLInputElement && selectedContainer instanceof HTMLElement) {
      const br = selectedContainer.getBoundingClientRect();
      const pl = br.width > 48 ? br.width + 2 : 2;
      inputEl.style.paddingLeft = `${pl}px`;
      inputEl.style.width = '100%';
    }
  }

  deleteTag = (e) => {
    let valid = true
    if (e instanceof Object) {
      if (e.target) {
        valid = false
        if (e.target.classList.contains('filter')) {
          valid = true
        }
      }
    }
    if (valid) {
      let { selectedTags } = this.state;
      if (typeof e === 'number' && selectedTags instanceof Array && e < selectedTags.length) {
        selectedTags.splice(e, 1);
      } else {
        selectedTags = [];
      }
      const activeFilter = selectedTags.length > 0 ? selectedTags.map(tg => tg.id).join(',') : 'all';
      const filterName = selectedTags.map(tg => tg.name.split('[').shift().trim()).join(', ');
      this.setState({
        selectedTags,
        activeFilter,
        filterName
      })
      setTimeout(() => {
        this.load(true)
        this.setInputMarginLeft()
      }, 200)
    }
  }

  handleSuggestions = (txt, suggestions) => {
    let lcTxt = txt.toLowerCase()
    return suggestions.filter(suggestion => {
      return suggestion.name.toLowerCase().includes(lcTxt)
    })
  }

  combineTags = () => {
    let { artists, tags } = this.state
    return artists.concat(tags).map(item => {
      let txt = ''
      let name = ''
      let sType = ''
      if (item.displayName) {
        sType = 'artist'
        txt = item.displayName
        name = item.displayName
      } else if (item.name) {
        sType = 'tag'
        txt = item.name
        name = item.name
      }
      if (sType) {
        txt += ' [' + sType + ']'
      }
      txt += ' (' + item.numImages + ')'
      let slug = item.slug ? item.slug : item._id

      return {
        id: item._id,
        text: txt,
        slug: slug,
        name: name,
        type: sType
      }
    })
  }

  render() {
    const {
      images,
      slideData,
      numImages,
      numArtists,
      total,
      restart,
      mainIndex,
      selectedTags,
      mixedTags,
      sort
    } = this.state
    let { title, filterName } = this.state
    title = spacedWord(title);
    const cls = ['top-bar']
    if (selectedTags.length > 0) {
      cls.push('is-filtered')
    }
    if (!filterName) {
      filterName = 'All'
    }
    if (sort) {
      cls.push('sort-' + sort);
    }
    const topClassNames = cls.join(' ')
    const rTagsClassNames = { searchWrapper: "textfield textfield-long" };
    const { labels } = config;
    return (
      <Container className={mainIndex >= 0 ? 'show-main' : 'thumbnails'}>
        <h1 className="main">
          <span className="title" onClick={this.clearCaches}>
            {title}
          </span>
        </h1>
        <div className={topClassNames}>
          <div className="info">
            <span className="filter small" onClick={e => this.deleteTag(e)} title="Reset tags">
              {filterName}
            </span>
            <span className="num-images label small">Pictures</span>
            <em
              className="num-images"
              title={`loaded ${numImages} of ${total}`}>
              {total}
            </em>

            <span className="artists label small">Artists</span>
            <em className="num-artists">{numArtists}</em>
          </div>
          <div className="sort-row row">
            <Icon className="sort" onClick={this.handleSort} title={labels.sortDefault}></Icon>
            <Icon className="sort amount down" onClick={this.handleSort} title={labels.sortNewest}></Icon>
            <Icon className="sort amount up" onClick={this.handleSort} title={labels.sortOldest}></Icon>
          </div>
          <ReactTags
            ref={this.rTags}
            tags={selectedTags}
            suggestions={mixedTags}
            classNames={rTagsClassNames}
            onDelete={this.deleteTag}
            onAddition={this.selectTag}
            placeholderText="Search by tag or artist"
            inline={false}
          />
        </div>
        <Grid
          images={images}
          restart={restart === true}
          setMain={this.setMain.bind(this)}
        />
        <FullImageSet
          data={slideData}
          initMainIndex={mainIndex}
          currMainIndex={mainIndex}
          unsetMain={this.unsetMain}
          setMain={this.setMain}
        />
      </Container>
    )
  }
}

export default Gallery
