import { notEmptyString } from "./modules/utils";

export const playerPatterns = () => {
	const video = [
		{ provider: "youtube", pattern: "youtu.be/[a-z0-9_-]+" },
		{ provider: "youtube", pattern: "www.youtube\\.com/watch\\?v=\\w+" },
		{ provider: "twitch", pattern: "www.twitch\\.tv/(videos/\\d+|\\w+)" },
		{ provider: "streamable", pattern: "streamable\\.com/\\w+" },
		{ provider: "vimeo", pattern: "vimeo\\.com/\\d+" },
		{ provider: "wistia", pattern: "home.wistia.com/medias/\\w+" },
		{ provider: "dailymotion", pattern: "www.dailymotion.com/video/\\w+" },
		{ provider: "twitch", pattern: "www.twitch.tv/videos/\\d+" },
		{ provider: "vimeo", pattern: "vimeo\\.com/(event|showcase)/\\w+" },
	];
	return { video };
};

export const validPlayerUrlType = (url) => {
	const patternSets = playerPatterns();
	let valid = false;
	let type = "";
	let provider = "";
	const prefixRgx = /^\w+:/;
	if (notEmptyString(url, 5)) {
	Object.entries(patternSets).forEach((entry) => {
		const [category, patterns] = entry;
		if (patterns instanceof Array) {
			patterns.forEach((item) => {
			const prefix = prefixRgx.test(item.pattern) ? "" : "https://";
			const rgx = new RegExp("^" + prefix + item.pattern, "i");
			if (rgx.test(url)) {
				valid = true;
				type = category;
				provider = item.provider;
			}
			});
		}
	});
	}

	return { valid, type, provider };
};
  