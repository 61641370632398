import React from 'react'
import { Container, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { applyPageContent } from '../../modules/cms'
import renderHTML from 'react-render-html'
import Photo from '../elements/photo'
import { fetchCurations } from '../../modules/api'
import { spacedWord } from '../../modules/utils'

class CurationsList extends React.Component {
  _isMounted = false

  state = {
    title: 'Curated',
    text: '',
    curations: [],
    numCurations: 0
  }

  componentWillMount() {
    const { slug, pages } = this.props
    this._isMounted = true
    applyPageContent(slug, pages).then(vars => {
      this.setState(vars)
    })
    let mode = 'curation';
    switch (slug) {
      case 'folder':
      case 'folders':
        mode = 'folder';
        break;
    }
    fetchCurations(mode).then(d => {
      if (d.valid && this._isMounted) {
        this.setState({
          curations: d.items.map(c => {
            c.link = 'curated/' + c.slug
            let cls = ['preview-image']
            let lcs = ['link']
            if (c.numSelected < 2) {
              cls.push('no-images')
              lcs.push('no-images')
            }
            c.classNames = cls.join(' ')
            c.linkClassNames = cls.join(' ')
            return c
          }),
          numCurations: d.items.length
        })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { title, text, curations, numCurations } = this.state
    const titleSpacedWords = title.split(' ').map(spacedWord);
    return (
      <Container className="main-content">
        <h1 className="main title">{titleSpacedWords.map((word, wi) => (
          <span className="word" key={['curated-title-word', wi].join('-')}>{word}</span>
        ))}</h1>
        <div className="text-content">{renderHTML(text)}</div>
        <Segment.Group id="curations-list" horizontal>
          {numCurations > 0 &&
            curations.map(curation => (
              <Segment key={curation._id} as="figure">
                <Link to={curation.link} className={curation.classNames}>
                  {curation.hasProfileUri && (
                    <Photo
                      img={curation.mainImage}
                      height={600}
                      width={600}
                      crop="fill"
                      className="profile-image"
                    />
                  )}
                </Link>
                <figcaption>
                  <h3 title={curation.user.displayName}>
                    <Link to={curation.link} className={curation.linkClassNames}>
                      {curation.title}
                    </Link>
                  </h3>
                  <p className="description bio">{curation.bio}</p>
                </figcaption>
              </Segment>
            ))}
        </Segment.Group>
      </Container>
    )
  }
}

export default CurationsList
