import Sortable from "sortablejs";

const sortableElement = (
  element,
  handle = ".handle",
  callback = null
) => {
  return new Sortable(element, {
    animation: 500,
    sort: true,
    easing: "cubic-bezier(1, 0, 0, 1)",
    handle,
    delay: 50,
    // Element dragging ended
    onEnd: function (e) {
      if (callback instanceof Function) {
        callback(e);
      }
    },
  });
};

export default sortableElement;
