import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import Picture from './picture'
import renderHTML from 'react-render-html'
import SocialShare from './social-share'
import ClaimForm from './claim-form'
import { likeImage } from '../../modules/api'
import { fromLocal } from '../../modules/localstore'
import config from '../../config/config'
import { notEmptyString, validUrl, mapLink, extractPlacenameAndGeo } from '../../modules/utils'
import { validPlayerUrlType } from '../../media-validator'
import { sanitize } from "../../modules/utils";

class MainPhoto extends Component {

  _isMounted = false

  state = {
    numLikes: 0,
    hasLiked: false,
    hover: false,
    showEmbed: false,
    showClaimForm: false,
  }

  componentDidMount() {
    this.assignLikes()
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this._isMounted) {
      let prevId = ''
      if (prevProps.img) {
        if (prevProps.img._id) {
          prevId = prevProps.img._id.toString()
        }
        let { img, className } = this.props
        let currId = img._id.toString()
        if (className !== prevProps.className) {
          if (className !== 'active') {
            this.setState({ showEmbed: false });
          }
        }
        if (prevId !== currId) {
          this.assignLikes()
        }
      }
    }
  }

  assignLikes = () => {
    let { likes } = this.props.img
    let stored = fromLocal('likes', config.likes.timeoutHours * 3600)
    let hasLiked = false
    if (stored.valid) {
      if (stored.data instanceof Array) {
        let item = stored.data.find(
          it => it.imgId.toString() === this.props.img._id.toString()
        )
        if (item) {
          likes = item.num
          hasLiked = true
        }
      }
    }
    this.setState({
      numLikes: likes,
      hasLiked: hasLiked
    })
  }

  like = () => {
    likeImage(this.props.img._id).then(d => {
      if (d.num) {
        this.setState({
          numLikes: d.num,
          hasLiked: true
        })
      }
    })
  }

  setHover = over => {
    this.setState({
      hover: over !== false
    })
  }

  toggleEmbed = (e, mode = 0) => {
    const { showEmbed } = this.state
    const hasEmbed = validPlayerUrlType(this.props.img.embed).valid;
    const newMode = hasEmbed ? mode < 0 ? false : mode > 0 ? true : !showEmbed : false;
    this.setState({ showEmbed: newMode })
  }

  buildDescription = () => {
    const { img } = this.props
    const parts = []
    if (typeof img.description == 'string' && img.description.length > 5) {
      parts.push(img.description)
    }
    if (img.description) {
      parts.push(img.description)
    }
    if (img.user.displayName) {
      parts.push(img.user.displayName)
    }
    return parts.join(' - ')
  }

  /*   calcVideoSize() {
      const videoEl = document.querySelector('.main-images');
      let videoWidth = 0
      let videoHeight = 540
      if (videoEl instanceof HTMLElement) {
        videoHeight = Math.floor(videoEl.clientWidth * (9 / 16))
        const rect = videoEl.getBoundingClientRect();
        const maxHeight = Math.floor(rect.bottom - rect.top)
        if (videoHeight > maxHeight) {
          videoHeight = maxHeight
          videoWidth = Math.floor((maxHeight / 9) * 16);
        }
      }
      const width = videoWidth > 0 ? `${videoWidth}px` : '100%';
      return { height: `${videoHeight}px`, width }
    } */

  filterByUserLink(user = null) {
    let str = '#';
    if (user instanceof Object) {
      str = '/gallery/user/' + sanitize(user.displayName);
    }
    return str;
  }

  claimToggle = (e) => {
    const { showClaimForm } = this.state
    this.setState({ showClaimForm: showClaimForm !== true })
  }

  closeClaimForm = (e) => {
    this.setState({ showClaimForm: false })
  }

  render() {
    const { img, sizes } = this.props
    const { labels } = config;
    const { numLikes, hasLiked, showEmbed, showClaimForm } = this.state
    const hasDescription =
      typeof img.description === 'string' && img.description.length > 2
    let hasUser = false
    if (img.user) {
      if (img.user.displayName) {
        hasUser = true
      }
    }
    let cls = [img.className]
    if (this.state.hover) {
      cls.push('over')
    }
    if (showEmbed) {
      cls.push('show-embed');
    }
    if (showClaimForm) {
      cls.push('show-claim-form');
    }
    const wrapperClasses = cls.join(' ');

    const id = ['image', img._id].join('--');

    const description = this.buildDescription()
    cls = ['user circle outline']
    const claimClassNames = cls.join(' ')

    cls = ['heart']
    if (!hasLiked) {
      cls.push('outline')
    }
    const likeClassNames = cls.join(' ')
    const hasOrgName = notEmptyString(img.user.orgName, 2);
    const imgId = img._id.toString();
    const orgName = hasOrgName ? img.user.orgName : '';
    const hasEmbed = validUrl(img.embed);
    const { hasGeo, hasPlacename, placename, coordStr } = extractPlacenameAndGeo(img);
    const userLoc = extractPlacenameAndGeo(img.user);
    const urlItems = img.user.urls instanceof Array ? img.user.urls.filter(url => url instanceof Object).map(item => mapLink(item, orgName)) : [];
    const hasUrls = urlItems.length > 0;
    const imageUrlItems = img.urls instanceof Array ? img.urls.filter(url => url instanceof Object).map(item => mapLink(item, '', true)) : [];
    const hasImageUrls = imageUrlItems.length > 0
    const displayName = hasUser ? img.user.displayName || '' : '';
    const hasArtist = img.artist instanceof Object;
    const artistApproved = hasArtist && img.artist.approved === true;
    const enableClaimForm = !hasArtist || !artistApproved;
    return (
      <figure className={wrapperClasses}>
        <div className="image-anchor" id={id}></div>
        <Icon className={likeClassNames} onClick={this.like}>
          {numLikes > 0 && <span className="num">{numLikes}</span>}
        </Icon>
        {enableClaimForm && <Icon className={claimClassNames} onClick={this.claimToggle}>
          <span className="help-text">{labels.claimButtonLabel}</span>
        </Icon>}
        <Picture img={img} sizes={sizes} setHover={this.setHover} />
        <figcaption>
          <div className="caption">
            <span className="title">{img.caption}</span>
            {hasUser && (
              <span className="user-display-name right">
                <Link to={this.filterByUserLink(img.user)}>
                  <em className="label">{config.labels.photographer}</em>
                  <span className="content">{displayName}</span>
                </Link>{' '}
              </span>
            )}
            {hasOrgName && (
              <span className="organisation">
                <em className="label">Organisation</em>
                <span className="text">{orgName}</span>
              </span>
            )}
            {userLoc.hasPlacename && (
              <p className="location">
                <em className="label">Location</em>
                <span className="content">{userLoc.placename}</span>
                {userLoc.hasGeo && <span className="coordinates"><Icon className="map marker alternate" /><span className="text-label">{userLoc.coordStr}</span></span>}
              </p>
            )}
            {hasUrls && (<ul className="web-links user-urls row">{
              urlItems.map((item, ii) => (
                <li key={['url', item.url, ii].join('-')}>
                  <a href={item.uri} title={item.uri} target="_blank" rel="noopener noreferrer">
                    <Icon className={item.iconClass} />
                    <span className="text">{item.title}</span>
                  </a>
                </li>
              ))

            }</ul>)}
          </div>
          {hasEmbed && <div className="media-embed"><span className="play-media" onClick={this.toggleEmbed} ><Icon className="video" /><span className="text-label">Play</span></span> <a href={img.embed} target="_blank" rel="noopener noreferrer" title={img.embed}>Open media</a></div>}
          <div className="description">
            {hasDescription && <article className="text">{renderHTML(img.description)}</article>}
            {hasPlacename && (
              <p className="location">
                <em className="label">Location</em>
                <span className="content">{placename}</span>
                {hasGeo && <span className="coordinates"><Icon className="map marker alternate" /><span className="text-label">{coordStr}</span></span>}
              </p>
            )}
          </div>
          {hasImageUrls && (<ul className="web-links image-urls row">{
            imageUrlItems.map((item, ii) => (
              <li key={['url', item.url, ii].join('-')}>
                <a href={item.uri} title={item.uri} target="_blank" rel="noopener noreferrer">
                  <Icon className={item.iconClass} />
                  <span className="text">{item.title}</span>
                </a>
              </li>
            ))

          }</ul>)}
          {img.tags.length > 0 && (
            <ul className="tags">
              {img.tags.map(t => (
                <li key={t._id}>{t.name}</li>
              ))}
            </ul>
          )}
          <SocialShare
            title={img.caption}
            path={`/gallery/${img._id}`}
            tags={img.tags}
            media={img.uri}
            description={description}
          />
        </figcaption>
        {enableClaimForm && <ClaimForm imgId={imgId} handleSubmit={this.closeClaimForm} />}
      </figure>
    )
  }
}

export default MainPhoto
