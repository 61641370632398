import React from 'react'
import PropTypes from "prop-types"
import { Icon } from 'semantic-ui-react'
import { latLngFormatter, notEmptyString, validCoords, validUKPostcode } from '../../modules/utils'
import { matchLocation } from '../../modules/api'

class Location extends React.Component {

	state = {
		locSuggestions: [],
		searching: false
	}

	searchLocation = (lookupMode = false) => {
		const elem = document.getElementById('placename-search')
		if (elem) {
			const { value } = elem
			const placename = value.trim();
			const valid = (lookupMode && notEmptyString(placename, 3)) || validUKPostcode(placename);
			if (valid) {
				const { searching } = this.state;
				if (!searching) {
					this.setState({ searching: true })
					matchLocation(placename).then(data => {
						if (data.valid) {
							const { items } = data
							if (items instanceof Array && items.length > 0) {
								const { lat, lng } = items[0];
								if (typeof lat === 'number' && typeof lng === 'number') {
									this.setState({ locSuggestions: items })
								}
							}
						}
					})
					setTimeout(() => {
						this.setState({ searching: false })
					}, 500);
				}
			}
			const { update, mode, coords } = this.props
			if (update instanceof Function) {
				update({ placename: value, coords }, mode);
			}
		}
	}

	confirmLocation = (locSug = null) => {
		if (locSug instanceof Object) {
			const { lat, lng, location } = locSug;
			const { update, mode } = this.props;
			this.setState({ locSuggestions: [] })
			if (update instanceof Function) {
				update({ coords: { lat, lng }, placename: location }, mode)
			}
		}
	}

	render() {
		const { placename, coords, editable } = this.props;
		const { locSuggestions } = this.state
		const hasCoords = validCoords(coords);
		const coordSearchMessage = 'Click on the icon to match the location';
		const coordStr = hasCoords ? latLngFormatter(coords) : coordSearchMessage;
		const hasLocSuggestions = locSuggestions.length > 0;
		return (
			<div className="location">
				<input
					name="placename"
					type="text"
					id="placename-search"
					value={placename}
					minLength={2}
					size={64}
					placeholder="Location"
					className="textfield textfield-long"
					onChange={() => this.searchLocation(false)}
					disabled={!editable}
				/>
				<p className="coordinates"><Icon className="map marker alternate" onClick={() => this.searchLocation(true)} title={coordSearchMessage} /><span className="text-label">{coordStr}</span></p>
				{hasLocSuggestions && (<ol className="suggestions plain">{locSuggestions.map(sug => (<li key={sug.coordStr} onClick={() => this.confirmLocation(sug)}><strong>{sug.coordStr}</strong> <span className="placename">{sug.location}</span></li>))}</ol>)}

			</div>
		)
	}
}

Location.propTypes = {
	mode: PropTypes.string,
	placename: PropTypes.string,
	coords: PropTypes.object,
	editable: PropTypes.bool,
	update: PropTypes.func
};

Location.defaultProps = {
	mode: 'image',
	placename: "",
	coords: {
		lat: 0,
		lng: 0
	},
	editable: true
};

export default Location